/// Voting
.voting__list {
	padding-left: 0;

	.voting__list__item {
		@include flex(row, center, space-between, wrap);
		margin: rem(10px 0);
	}

	.voting__list__answer {
		@include createColumn(s, 10);
		border: rem(1px) solid $list-border-color;
		background: $background-light;
		padding: rem(20px);
		transition: all .3s ease-out;
		border-radius: $border-radius;

		&:last-child {
			border: rem(1px) solid $list-border-color;
		}
	}

	.voting__list__item--selected {
		.voting__list__answer {
			border-color: $primary-color;

			color: $primary-color;
		}
	}

	.voting__item__submit {
		@include createColumn(s, 10);
		@include flex(row, center, flex-end, nowrap);
		padding-right: 0;
		height: 0;
		margin-top: 0.25rem;
	}
}

.voting__list__check,
.voting__list__result {
	@include createColumn(s, 2);
}

.voting__results {
	display: none;

	.voting__list__item {
		&:first-child {
			.voting__list__result {
				font-weight: bold;
			}
		}
	}

	.voting__list__answer {
		background: transparent;
		position: relative;

		&::before {
			content: "";
			background-color: $background-light;
			position: absolute;
			height: 100%;
			width: 100%;
			left: 0;
			top: 0;
		}

		.voting-bar {
			background-color: $list-border-color;
			position: absolute;
			height: 100%;
			left: 0;
			top: 0;
		}

		span {
			position: relative;
			z-index: 1;
		}
	}

}
