// Slick Carousel
.carousel-slider {
	&--big {
		padding-top: 1.8rem;
	}

	.slick-list {
		max-width: calc(100% - 160px);
		margin: 0 auto;
	}

	.slide {
		transition: all .2s ease-in-out;
		opacity: .7;
	}

	.slide:hover,
	.slick-current .slide {
		transform: scale(1.1);
		opacity: 1;
	}
	
	.carousel-slider__image {
		margin: 0;

		.carousel-slider__caption {
			display: none;
		}
	}

	.slick-dots {
		bottom: rem(-25px);
	}
}

// News Slider Styles
.news-slider {
	.slick-prev,
	.slick-next {
		height: calc(100% - #{$slider-height-mobile});
		transform: none;
		top: 0;

		@include media-breakpoint-up(m) {
			height: calc(100% - #{$slider-height-desktop});
		}

		&::before {
			opacity: .4;
		}
	}

	.slick-arrow {
		&.is--hovered {
			@include media-breakpoint-up(m) {
				background-color: rgba($background-light, .3);

				&::before {
					opacity: 1;
				}
			}

		}
	}
}


.radio-console-slider {
	.carousel-slider .slick-list {
		@include media-breakpoint-down(sm) {
			max-width: 100% !important;	
			padding: 0 !important;
		}
	}
	.slick-slide {
		max-width: 96%;
		width: 270px;
	}
} 
