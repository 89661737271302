/// Article styles
.article__title {
	margin-bottom: 0;
}

.article__date {
	margin-top: 0;
}

/// Copyright information
.copyright {
	display: block;
	text-align: right;
	opacity: .5;
	padding-right: 1rem;
	padding-left: 1rem;
}