.traffic-tabs {
	&__control {
		margin-bottom: rem(32px);
	}

	&__row {
		padding: rem(24px 0);
	}
}

.traffic-tabs__row__partial {
	@include flex(row, flex-start, flex-start, nowrap);
	margin-top: rem(20px);
	width: 100%;

	.traffic-tabs__row__text {
		padding-left: rem(80px);
	}

	.traffic-tabs__row__source {
		display: block;
		opacity: .5;
	}

	&:first-of-type {
		border-top: 1px solid lighten($off-black, 50);
		padding-top: rem(20px);
	}
}

.traffic-tabs__row__icon {
	+ .traffic-tabs__row__text {
		padding-left: rem(20px);
	}
}

// Traffic tabs headline types with icons
.traffic-tabs__headline {
	@extend h5;
	color: $off-black;
	@include flex(columns, center, flex-start, nowrap);
}

.traffic-tabs__headline--has-icon {
	&::before {
		display: inline-block;
		margin: rem(0 20px 0 0);
		width: rem(55px);
	}

	&--speedcam {
		&::before {
			content: url('../../images/layout/svg/speedcam.svg');
		}
	}

	&--construction {
		&::before {
			content: url('../../images/layout/svg/construction.svg');
		}
	}

	&--trafficjam {
		&::before {
			content: url('../../images/layout/svg/trafficjam.svg');
		}
	}

	&--crash {
		&::before {
			content: url('../../images/layout/svg/crash.svg');
		}
	}
}

// Street symbols (highway, bundesstrasse)
.street-symbol {
	&__name {
		min-width: 100%;
		display: inline-block;
		text-align: center;
		text-transform: uppercase;
		font-weight: bold;
	}

	&--highway {
		.street-symbol__name {
			background-color: $color-highway;
			color: color("shades", "white");
		}

		&::before,
		&::after {
			content: '';
			display: block;
			width: 0;
			height: 0;
			border-left: rem(30px) solid transparent;
			border-right: rem(30px) solid transparent;
		}

		&::before {
			border-bottom: rem(5px) solid $color-highway;
		}

		&::after {
			border-top: rem(5px) solid $color-highway;
		}
	}

	&--bundesstrasse {
		display: block;
		border: rem(2px) solid $color-bundesstrasse;
		border-radius: rem(6px);

		.street-symbol__name {
			background-color: $color-bundesstrasse;
			border: rem(2px) solid black;
			border-radius: rem(4px);
			color: color("shades", "black");
			padding: rem(1px);
		}

	}
}

// Form 'Blitzer melden'
.speed-camera-form {
	margin-top: rem(16px);

	.input-field {
		margin-top: rem(32px);
	}

	.btn-large {
		background: $primary-color;
	}

	.waves-button-input {
		color: white;
	}
}

