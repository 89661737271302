.collapsible-header {
	i.collapsible-closed {
		display: block;
	}
	i.collapsible-open {
		display: none;
	}

	&.active {
		i.collapsible-closed {
			display: none;
		}
		i.collapsible-open {
			display: block;
		}
	}

}