/// Breaker Element
.breaker__text {
	position: absolute;
	bottom: 5%;
	left: 5%;
}

.breaker__topic {
	color: $primary-color;
	background-color: $background-light;
	padding: rem(10px 20px);
	border-radius: .5rem;
	font-family: $font-stack-headlines;
	font-weight: bold;
	text-transform: uppercase;
	font-size: rem(20px);

	@include media-breakpoint-up(m) {
		font-size: rem(25px);
	}
}

.breaker__headline {
	color: color("shades", "white");
	font-weight: bold;
	margin-bottom: 0;
	line-height: 95%;

	&__link {
		color: color("shades", "white");
	}
}

.z-depth-1 {
	.breaker__headline {
		text-shadow: 2px 0 2px color("grey", "darken-1");
	}
}