nav.breadcrumb {
	font-family: "Roboto", sans-serif;
	background: none;
	box-shadow: none;
	height: auto;
	line-height: 1.3;
	i.mdi {
		line-height: inherit !important;
		height: auto;
	}
	a{
		color: $off-black;
		&:hover{
			color: $primary-color;
		}
	}
}

// Breadcrumbs
.breadcrumb {
	font-size: rem(14px);
	line-height: .75;

	i,
	[class^="mdi-"], [class*="mdi-"] {
		display: inline-block;
		float: left;
		font-size: rem(20px);
	}

	&:before {
		content: '\00BB';
		color: $primary-color;
		display: inline-block;
		font-family: 'Material Icons';
		font-weight: normal;
		font-style: normal;
		margin: 0 10px 0 8px;
		font-size: 18px;
		-webkit-font-smoothing: antialiased;
		line-height: .75;
	}

	&:first-child:before {
		display: none;
	}

	&:last-child {
		color: $off-black;
	}
}