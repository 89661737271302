// Advertising wrapper box
.ad__wrapper {
	display: inline-block;

	img, iframe {
		max-width: 100%;
	}

	& > a {
		display: inline-block;
	}

	&:not(:empty) {
		&::before {
			content: "Anzeige";
			display: block;
			font-size: $font-size-small;
			opacity: .5;
			color: $off-black;
			text-align: left;
		}
	}

	&--right {
		@include flex(column, center, flex-start, nowrap);
	}

	&--center {
		text-align: center;
	}

	&--inline {
		position: relative;

		&:not(:empty) {
			&::before {
				position: absolute;
				background-color: $background-light;
				top: 0;
				right: 0;
				z-index: 1;
				opacity: 1;
				padding: 0 rem(3px);
			}
		}
	}

	&--superbanner {
		display: none;

		&:not(:empty) {
			&::before {
				background: white;
				text-align: center;
				display: block;
				width: 46px;
			}
		}

		@include media-breakpoint-up(l) {
			display: block;
		}
	}

	// TODO: Refactor media queries
	&--skyscraper {
		display: none;

		@media screen and (min-width: 1500px) {
			display: block;
			position: absolute;
		}
	}

	&--skyscraper--left {
		@media screen and (min-width: 1500px) {
			left: 15px;
		}
	}

	&--skyscraper--right {
		@media screen and (min-width: 1500px) {
			right: 15px;
		}
	}
}

// ad auto section
.ad__wrapper--autoSection {
	position: relative;

	&::before {
		position: absolute;
		top: 1.8rem;
		left: 0;
	}

	& > * {
		margin-bottom: 1.8rem;
		margin-top: 2.8rem;
	}
}

// fix display of news_items in slider
.news-slider .news-teaser__item__inner.ad__wrapper {
	display: block;
}


// ad wallpaper
.ad__wallpaper {
	display: flex;
	justify-content: flex-end;

	@media (max-width: 1645px) {
		display: none !important;
	}

	&__aligner {
		position: relative;
		margin-right: -26px;
		width: 728px;
	}

	&__left {
		width: 728px;
	}

	&__right {
		position: absolute;
		width: 160px;
		height: 600px;
		right: -160px;
		top: 0px;
	}
}

.ad__card {
	display: block !important;
}