.media .card-content {
	position: relative;
}

.play {
	position: absolute;
	top: 0;
	right: 0;
	transform: translateY(-50%) translateX(-50%);
	border-radius: 10px;
}
.audio {
	margin-top: 38px; //half the height of the play button - based on .btn-large + 10px
	.card-content {
		overflow: hidden;
	}

	span{
		display: block;
	}

	.mediatype {
		margin-right: 1em;
		margin-bottom: 1em;
		line-height: 0;
	}

	img {
		width: 100px;
	}
}

.audioplayer-simple {
	width: 100%;
}