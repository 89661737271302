@import "../helpers";

// TODO: Refactor styles

// Show figcation only when lightbox is open
.photoswipe-item {
	figcaption {
		display: none;
	}
}

// <button> css reset
.pswp__button {
	background: none;
	cursor: pointer;
	overflow: visible;
	-webkit-appearance: none;
	border: 0;
	margin: 0;
	box-shadow: none;

	&:focus,
	&:hover {
		opacity: 1;
	}

	&:active {
		outline: none;
		opacity: 0.9;
	}

	&::-moz-focus-inner {
		padding: 0;
		border: 0
	}
}

// 3. Index indicator ("1 of X" counter)

.pswp__counter {
	color: color("shades", "white");
	padding: rem(0 20px);
}

// 4. Caption

.pswp__caption {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	min-height: 72px;
	background-color: $background-light;
}

.pswp__caption__center {
	max-width: rem(800px);
	margin: 0 auto;
	padding: rem(15px);
}

.pswp__caption--empty {
	display: none;
}

// Fake caption element, used to calculate height of next/prev image
.pswp__caption--fake {
	visibility: hidden;
}

// 5. Loading indicator (preloader)
// You can play with it here - http://codepen.io/dimsemenov/pen/yyBWoR

.pswp__preloader {
	width: 44px;
	height: 44px;
	position: absolute;
	top: 0;
	left: 50%;
	margin-left: -22px;
	opacity: 0;
	transition: opacity 0.25s ease-out;
	will-change: opacity;
	direction: ltr;

	@include media-breakpoint-up(sm) {
		.pswp__preloader {
			position: relative;
			left: 50%;
			top: 50%;
			margin: 0;
			float: right;
		}
	}
}

.pswp__preloader__icn {
	width: 20px;
	height: 20px;
	margin: 12px;
}

.pswp__preloader--active {
	opacity: 1;

	.pswp__preloader__icn {
		// We use .gif in browsers that don't support CSS animation
		background: url('../../../images/layout/preloader.gif') 0 0 no-repeat;
	}
}

.pswp--css_animation {
	.pswp__preloader--active {
		opacity: 1;

		.pswp__preloader__icn {
			animation: clockwise 500ms linear infinite;
		}

		.pswp__preloader__donut {
			animation: donut-rotate 1000ms cubic-bezier(.4, 0, .22, 1) infinite;
		}
	}

	.pswp__preloader__icn {
		background: none;
		opacity: 0.75;
		width: 14px;
		height: 14px;
		position: absolute;
		left: 15px;
		top: 15px;
		margin: 0;
	}

	.pswp__preloader__cut {
		// The idea of animating inner circle is based on Polymer ("material") loading indicator
		// by Keanu Lee https://blog.keanulee.com/2014/10/20/the-tale-of-three-spinners.html
		position: relative;
		width: 7px;
		height: 14px;
		overflow: hidden;
	}

	.pswp__preloader__donut {
		box-sizing: border-box;
		width: 14px;
		height: 14px;
		border: 2px solid #FFF;
		border-radius: 50%;
		border-left-color: transparent;
		border-bottom-color: transparent;
		position: absolute;
		top: 0;
		left: 0;
		background: none;
		margin: 0;
	}
}

@keyframes clockwise {
	0% {
		transform: rotate(0deg)
	}
	100% {
		transform: rotate(360deg)
	}
}

@keyframes donut-rotate {
	0% {
		transform: rotate(0)
	}
	50% {
		transform: rotate(-140deg)
	}
	100% {
		transform: rotate(0)
	}
}

// 6. Additional styles

// root element of UI
.pswp__ui {
	visibility: visible;
	opacity: 1;
	z-index: $pswp__root-z-index + 50;
}

// top black bar with buttons and "1 of X" indicator

.pswp__caption,
.pswp__top-bar,
.pswp--has_mouse .pswp__button--arrow--left,
.pswp--has_mouse .pswp__button--arrow--right {
	-webkit-backface-visibility: hidden;
	will-change: opacity;
	transition: opacity $pswp__controls-transition-duration cubic-bezier(.4, 0, .22, 1);
}

// pswp--has_mouse class is added only when two subsequent mousemove events occur
.pswp--has_mouse {
	.pswp__button--arrow--left,
	.pswp__button--arrow--right {
		visibility: visible;
	}
}

.pswp__top-bar {
	background-color: $primary-color;
}

// pswp__ui--fit class is added when main image "fits" between top bar and bottom bar (caption)
.pswp__ui--fit {
	.pswp__top-bar {
		background-color: $primary-color;
	}
}

// Custom Styles

.pswp__top-bar {
	position: absolute;
	left: 0;
	top: 0;
	height: rem(72px);
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem(0 20px);

	.pswp__buttons__right {
		margin-left: auto;
	}

	.pswp__button {
		padding: rem(10px);
		font-size: rem(24px);
		color: color("shades", "white");
	}
}

.pswp__button {
	font-family: "Material Design Icons";

	&:focus {
		background: none;
	}

	&--arrow--left,
	&--arrow--right {
		font-size: rem(100px);
		position: absolute;
		top: 50%;
		margin-top: rem(-50px);
		color: color("grey", "lighten-3");
	}

	&--arrow--left {
		left: 0;

		&::before {
			content: "\F141";
		}
	}

	&--arrow--right {
		right: 0;

		&::before {
			content: "\F142";
		}
	}

	&--close {
		&::before {
			content: "\F156";
		}
	}

	&--fs {
		&::before {
			content: "\F293";
		}
	}

	&--zoom {
		&::before {
			content: "\F6EC";
		}
	}
}

.pswp--zoomed-in .pswp__button--zoom {
	&::before {
		content: "\F6EB";
	}
}

// no arrows on touch screens
.pswp--touch {
	.pswp__button--arrow--left,
	.pswp__button--arrow--right {
		visibility: hidden;
	}
}

// pswp__ui--one-slide class is added when there is just one item in gallery
.pswp__ui--one-slide {
	.pswp__button--arrow--left,
	.pswp__button--arrow--right,
	.pswp__counter {
		display: none;
	}
}

// pswp__ui--hidden class is added when controls are hidden
// e.g. when user taps to toggle visibility of controls

.pswp__ui--hidden {
	.pswp__top-bar,
	.pswp__caption,
	.pswp__button--arrow--left,
	.pswp__button--arrow--right {
		// Force paint & create composition layer for controls.
		opacity: 0.001;
	}
}

// Videos in Photoswipe
.pswp__zoom-wrap {
	text-align: center;
	&:before {
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle;
	}
}

.wrapper {
	line-height: 0;
	width: 100%;
	max-width: rem(900px);
	position: relative;
	display: inline-block;
	vertical-align: middle;
	margin: 0 auto;
	text-align: left;
	z-index: 1045;
}

.video-wrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: rem(25px);
	height: 0;
	width: 100%;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

video {
	width: 100% !important;
	height: auto !important;
}

.pswp--zoom-allowed .pswp__img {
	cursor: default;
}

.pswp {
	z-index: 1000010;
}

.pswp-small-logo {
	padding-bottom: 0.625rem;
	padding-top: 0.625rem;
	margin-right: 0.625rem;
	max-height: 100%;
	flex-shrink: 0;

	&__img {
		max-height: 3.25rem;
	}
}

.pswp__top-bar__container {
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding: 0 1.25rem;
    height: 4.5rem;
    width: 100%;
}

.pswp__container {
	.pswp-small-logo img {
		height: 3.375rem !important;
	}
}













