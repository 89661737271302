// Vertical tabs
.tabs .active .btn {
	background-color: $primary-color;
}

.tabs__wrapper {
	background-color: $background-light;
	padding: 0;
}

.row [class*="col"] + [class*="tabs__wrapper"] {
	margin-top: 0;
}

.tab__title {
	display: block;
	line-height: 1;
	font-size: .7rem;
	text-transform: none;
	color: $off-black;

	@include media-breakpoint-up(m) {
		padding: .5rem 0;
	}
}

.tabs {
	&--vertical {
		height: $tab-nav-height;
		background-color: $tab-background;

		@include media-breakpoint-up(m) {
			flex-direction: column;
			height: 100%;
			padding: 1rem 0;
		}

		.tab {
			height: $tab-nav-height;
			line-height: 75px;
			display: block;

			@include media-breakpoint-up(m) {
				padding: 0 0 1rem 0;
				height: calc(100% / 3);
				line-height: normal;
				flex-grow: 0;
			}

			a {
				text-overflow: initial;
				position: relative;
				padding: 0;

				@include media-breakpoint-up(m) {
					padding: 1.5rem 0;
				}

				&:hover,
				&:focus {
					background-color: $tab-background-active;
				}

				&.active {
					background-color: $tab-background-active;

					&:focus {
						background-color: $tab-background-active;
					}

					&::before {
						content: "";
						border-top: 3px solid $primary-color;
						position: absolute;
						left: auto;
						right: 0;
						width: 100%;

						@include media-breakpoint-up(m) {
							border-left: 3px solid $primary-color;
							width: 3px;
							height: 100%;
							top: 0;
						}
					}

					.tab__title {
						color: $primary-color;
					}
				}
			}
		}

		.indicator {
			display: none;
		}
	}
}

// Horizontal tabs
.tabs {
	&:not(.tabs--vertical) {
		a {
			cursor: pointer;
			transition: none;

			&:hover,
			&:focus,
			&.active,
			&:focus.active {
				border-bottom: 2px solid $primary-color;
				background-color: transparent;
			}
		}
	}
}