.breaking-news {
	overflow: hidden;
	background-color: #fff;
	padding-bottom: 1.25rem;
	margin-bottom: 1px;
	padding-top: 0;
	align-items: center;

	@include media-breakpoint-up(l) {
		padding-bottom: 0;
	}
}

.breaking-news__attention {
	background-color: $primary-color;
	color: #fff;
	padding: 1.5rem 0;
}

.breaking-news__content {
	padding: 1.5rem 1rem 1rem 1rem;

	@include media-breakpoint-up(m) {
		@include flex(row, center, space-between, nowrap);
		padding: 1rem;
	}

	@include media-breakpoint-up(l) {
		padding: 0 1rem;
	}
}

.breaking-news__content__social {
	position: relative;
	padding-top: .75rem;
	z-index: 2;

	@include media-breakpoint-up(m) {
		flex: 0 0 18%;
		padding-top: 0;
	}
}