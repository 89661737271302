/// Default news teaser with multiple variations
.news-teaser {
  font-size: rem(16px);

  .card {
    height: 100%;
    margin: 0;
  }
}

.news-teaser__item {
  padding-bottom: rem(15px);
  padding-top: rem(15px);
  height: 100%;
}

.teaser-link {
  margin-right: rem(10px);
  font-weight: bold;
  position: relative;
  z-index: 2;
}

/// Big news teaser
.news-teaser-single {
  .news-teaser__item {
    padding: 0;
  }
}

/// News items in slick slider
.news-slider {
  position: relative;

  &.slick-dotted {
    margin-bottom: 1rem;
  }

  .news-teaser__item {
    padding: 0;
  }

  .news-teaser__content {
    background-color: $background-light;
    padding: 3rem 1.5rem 1.5rem 1.5rem;
    height: $slider-height-mobile;

    @include media-breakpoint-up(m) {
      height: $slider-height-desktop;
    }
  }

  .slick-dots {
    bottom: calc(#{$slider-height-mobile} - 40px);

    @include media-breakpoint-up(m) {
      bottom: calc(#{$slider-height-desktop} - 40px);
    }
  }
}

/// Horizontal news teaser
.news-teaser__item--horizontal {
  .news-teaser__item__inner {
    @include media-breakpoint-up(m) {
      @include flex;
    }
  }

  .news-teaser__image {
    @include createColumn(m, 3);
    padding: 0;

    @include media-breakpoint-up(m) {
      height: rem(200px);
    }
  }

  .news-teaser__image img {
    @include media-breakpoint-up(m) {
      height: 100%;
      object-fit: cover;
      border-bottom-left-radius: $card-border-radius !important;
      border-top-left-radius: $card-border-radius !important;
      border-bottom-right-radius: 0px !important;
      border-top-right-radius: 0px !important;
    }
  }

  .news-teaser__content {
    @include createColumn(m, 9);
    padding-top: 0;

    @include media-breakpoint-up(m) {
      padding: 1.5rem;
    }
  }

  .news-teaser__title {
    @include media-breakpoint-up(m) {
      margin-top: 0;
    }
  }
}

/// Videos in news teasers
.news-teaser__video {
  position: relative;
  z-index: 2;

  &__item {
    position: relative;
    padding-bottom: 58.5%; // 16:10
    padding-top: rem(25px);
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

// Mobile compact view
.news-teaser--compact {
    @include media-breakpoint-down(m) {
      .news-teaser__content {
        > p {
          display: none;
        }
        > a {
          display: flex !important;
        }
      }

      .news-teaser__item {
        min-height: 205px;
      }

      .news-teaser__item__inner {
        display: flex;
      }

      .news-teaser__video,
      .news-teaser__image {
        flex: 0 0 50%;
      }

      .news-teaser__image {
        img {
          height: 100%;
          object-fit: cover;
        }
      }

      .news-teaser__video__item {
        height: 100%;
      }

      .news-teaser__title {
        font-size: 1.1rem;
      }
    }
}