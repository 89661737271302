// Social media links
.socials {
	.socials__link {
		display: inline-block;
		color: color("shades", "white");
		font-size: rem(35px);
		margin: rem(0 10px);

		@include media-breakpoint-down(sm) {
			margin: 0;
		}

		&:first-of-type {
			margin-left: 0;
		}

		&:last-of-type {
			margin-right: 0;
		}

		&--svg {
			display: flex;

			> svg {
				max-width: 53%;
				max-height: 58%;

				> g {
					transform: scale(0.3);
				}
			}
		}
	}

	.nav-mobile__upper__left &,
	.nav-mobile__stickybar__left &{
		.socials__link {
			margin: rem(0 2px);
		}
	}

	@include media-breakpoint-down(sm) {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
}