.cookie-notice {
	background: color("grey", "darken-2");
	position: fixed;
	z-index: 1000050;
	width: 100%;
	left: 0;
	top: 0;

	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);

	&__wrapper {
		justify-content: space-between;
		flex-wrap: wrap;
		display: flex;
		padding: 0px 10px 0px 10px;
		@include media-breakpoint-up(m) {
			flex-wrap: nowrap;
		}
	}

	&__left {
		align-items: center;
		display: flex;
		width: 100%;

		p { 
			color: color("shades", "white"); 
		}

		@include media-breakpoint-up(m) {
			padding-right: 4rem;
			width: auto;
		}
	}

	&__right {
		justify-content: center;
		align-items: center;
		display: flex;
		width: 100%;
		
		.btn {
			background: $primary-color;
		}
		
		@include media-breakpoint-up(m) {
			justify-content: flex-end;
			width: auto;
			min-width: 250px;
		}
	}
}