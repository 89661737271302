/// Datepicker

.datepicker-date-display {
	background-color: $primary-color;
}

.datepicker-cancel, .datepicker-clear, .datepicker-today, .datepicker-done {
	color: $primary-color;
}

.datepicker-table td.is-selected {
	background-color: $primary-color;
}

.datepicker-table td.is-today {
	color: $primary-color;
}

.datepicker-day-button:focus {
	background-color: color("grey", "lighten-3");
}

.datepicker-controls .select-month input {
	width: rem(85px);
}