// Header Search field
.header__search {
	margin: 0;
	display: inline-block;
	position: relative;
	height: rem(72px);
	min-width: rem(72px);
	float: right;
	padding: 0;
}

.search__field {
	color: color("shades", "white");
	height: rem(72px);
	display: inline-block;
	border: none;
	outline: none;
	padding: rem(5px 60px 5px 5px);
	width: 0;
	position: relative;
	top: 0;
	right: 0;
	background: none;
	z-index: 3;
	transition: width .4s cubic-bezier(0.000, 0.795, 0.000, 1.000);
	cursor: pointer;

	@include media-breakpoint-up(m) {
		padding: rem(5px 60px 5px 5px);
	}
}

.search__field--focus,
.search__field--active {
	width: calc(100vw - 140px);
	transform: translate3d(-100%, 0, 0);
	background-color: $primary-color;
	z-index: 1;
	border-bottom: 1px solid color("shades", "white");
	cursor: text;
	height: rem(68px);
	padding: rem(5px);

	@include media-breakpoint-up(m) {
		transform: none;
		height: rem(40px);
		width: rem(400px);
	}

	@include media-breakpoint-up(xl) {
		width: rem(800px);
	}

	+ .search__submit {
		right: rem(-40px);

		.search__submit__text {
			display: none;
		}
	}
}



.search__submit__text {
	padding-top: 0;
	margin-top: 0;
}

.search__submit {
	@include flex(column, center, center, nowrap);
	height: rem(72px);
	color: color('shades', 'white');
	line-height: rem(20px);
	border: none;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 2;
	cursor: pointer;
	background: transparent;

	@include media-breakpoint-up(m) {
		background: $primary-color;
	}

	&:focus,
	&:active {
		background: transparent;
	}

	&::before {
		content: '\F349';
		display: inline-block;
		font: normal normal normal 32px/1 "Material Design Icons";
		-webkit-font-smoothing: antialiased;

		@include media-breakpoint-up(m) {
			padding: rem(0 0 10px 0);
		}
	}
}

.header__search {
	.header__search__form {
		@include flex(column, center, center, nowrap);
		position: absolute;

		@include media-breakpoint-up(m) {
			background-color: $primary-color;
		}
	}
}

.search-tabs__control {
	margin-bottom: 1.5rem;

	.search-tabs__control__link {
		color: $off-black !important;
	}
}

.search-tabs__container {

	.card {
		height: calc(100% - 1rem);
	}

	.copyright {
		display: block;
		text-align: right;
		color: rgb(69, 69, 69);
		font-size: 0.8rem;
	}

	.tab-header {
		margin-bottom: 20px;
		padding: 10px 20px;
	}

	p {
		color: $off-black;
	}

	.search-item__article {
		display: block;

		.search-item__article__date {
			p {
				font-size: 0.75rem;
				margin-top: 0;
			}
		}

		.search-item__article__content {
			@include media-breakpoint-up(m) {
				@include flex(columns, flex-start, flex-start, nowrap);
			}

			img {
				width: 12rem;
				min-width: 12rem;
				max-width: 12rem;
				height: auto;
				margin-right: rem(10px);
			}
		}
	}

}

.search-item__audio {
	img {
		display: block;
		height: auto;
		width: 100%;
	}

	&__content {
		justify-content: space-between;
		align-items: center;
		display: flex;
		padding: rem(0 16px);
		height: 100%;

		&__left {
			.playlist__song__artist {
				display: block;
			}
		}

		&__right {
			i {
				background: $primary-color;
			}
		}
	}
}
