::placeholder {
	color: inherit;
}

input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0px 1000px white inset;
}

.input-field {
	margin-bottom: 0;
}

.input-field.col label {
	left: $grid-gutter-width;
}

/// Suffix icons
.suffix {
	position: absolute;
	width: $input-height;
	font-size: $input-icon-size;
	transition: color .2s;
	top: 25%;
	right: 0;

	&.mdi {
		height: rem(50px);
		line-height: rem(50px);
		display: inline-block;
	}

	&.active {
		color: $input-focus-color;
	}
}

/// Textarea
.infobox__container textarea.materialize-textarea {
	height: 7rem;
}

// Checkbox fill
[type="checkbox"].filled-in {
	&:checked {
		+ span:not(.lever):after {
			border: 2px solid $primary-color;
			background-color: $primary-color;
		}
	}
}

/// Range Slider (NoUiSlider)
.range-section {
	padding: 3rem 0 2rem 0;
}

.noUi-horizontal .noUi-handle,
.noUi-vertical .noUi-handle,
.noUi-target.noUi-horizontal .noUi-tooltip,
.noUi-target.noUi-vertical .noUi-tooltip,
.noUi-connect {
	background-color: $primary-color;
}

/// Textarea
.material-textarea {

	// General Styles
	background-color: transparent;
	border: none;
	border-bottom: $input-border;
	border-radius: 0;
	outline: none;
	height: auto;
	width: 100%;
	font-size: $input-font-size;
	margin: rem(10px 0);
	box-shadow: none;
	transition: box-shadow .3s, border .3s;
	line-height: normal;
	padding: rem(10px 0);
	resize: none;
	min-height: $input-height;
	box-sizing: border-box;

	// Disabled input style
	&:disabled,
	&[readonly="readonly"] {
		color: $input-disabled-color;
		border-bottom: $input-disabled-border;
	}

	// Disabled label style
	&:disabled+label,
	&[readonly="readonly"]+label {
		color: $input-disabled-color;
	}

	// Focused input style
	&:focus:not([readonly]) {
		border-bottom: 1px solid $input-focus-color;
		box-shadow: 0 1px 0 0 $input-focus-color;
	}

	// Focused label style
	&:focus:not([readonly])+label {
		color: $input-focus-color;
	}

	// Hide helper text on data message
	&.valid ~ .helper-text[data-success],
	&:focus.valid ~ .helper-text[data-success],
	&.invalid ~ .helper-text[data-error],
	&:focus.invalid ~ .helper-text[data-error] {
		@extend %hidden-text;
	}

	// Valid Input Style
	&.valid,
	&:focus.valid {
		@extend %valid-input-style;
	}

	// Custom Success Message
	&.valid ~ .helper-text:after,
	&:focus.valid ~ .helper-text:after {
		@extend %custom-success-message;
	}
	&:focus.valid ~ label {
		color: $input-success-color;
	}

	// Invalid Input Style
	&.invalid,
	&:focus.invalid {
		@extend %invalid-input-style;
	}

	// Custom Error message
	&.invalid ~ .helper-text:after,
	&:focus.invalid ~ .helper-text:after {
		@extend %custom-error-message;
	}
	&:focus.invalid ~ label {
		color: $input-error-color;
	}

	// Full width label when using validate for error messages
	&.validate + label {
		width: 100%;
	}

	// Form Message Shared Styles
	& + label:after {
		@extend %input-after-style;
	}
}

// Form Validation with shake animation
.invalid-shake {
	animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
	transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
	perspective: 1000px;
}