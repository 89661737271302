/// Off-Canvas navigation for mobile and desktop

.sidenav-overlay {
	background: $transparent-layer-color;
	z-index: 1000001;
}

.sidenav {
	width: 100%;
	max-width: 80%;
	padding-top: rem(20px);
	overflow: hidden;
	z-index: 1000002;

	@include media-breakpoint-up(m) {
		max-width: 50%;
	}

	@include media-breakpoint-up(l) {
		max-width: 30%;
	}

	li > a {
		line-height: 1;
		height: auto;
	}

	.sidenav-close {
		background-color: $primary-color;
		color: color("shades", "white");
		display: block;
		padding: rem(0 30px 0 30px);
		height: rem(72px);
		line-height: rem(72px);
		font-family: $font-stack-headlines;
		font-size: rem(28px);
		letter-spacing: $default-letter-spacing;
		font-weight: normal;
		cursor: pointer;
	}

	.sidenav-close__icon {
		display: inline-block;
		font-size: rem(28px);
		line-height: rem(72px);
		height: rem(72px);
		vertical-align: middle;
	}

	.sidenav-login {
		background-color: $body-color;
		padding: rem(0 30px 0 30px);
		font-family: $font-stack-headlines;
		font-size: rem(16px);
		letter-spacing: $default-letter-spacing;
		font-weight: bold;
		height: rem(40px);
		display: flex;

		@include media-breakpoint-down(sm) {
			flex-wrap: wrap;
			height: 72px;
		}

		&__link {
			display: flex;
			box-sizing: border-box;
			padding-right: rem(50px);
			line-height: rem(40px) !important;
			color: $sidenav-font-color;
			white-space: nowrap;

			@include media-breakpoint-down(xl) {
				margin-right: rem(10px);
			}

			@include media-breakpoint-down(sm) {
				line-height: 2rem !important;
				padding-left: 29px;
				width: 100%;

				&:first-child {
					padding-left: 0;
				}
			}

			&:last-child {
				margin-right: 0;
			}

			.mdi {
				margin-right: rem(10px);
			}

			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
	}
}

.sidenav__header {
	position: absolute;
	height: 100px;
	width: 100%;

	@include media-breakpoint-down(sm) {
		height: 140px;
	}
}

.sidenav__header__search {
	padding: rem(30px);
	color: #444;

	::placeholder {
		color: #444;
	}

	input {
		border-bottom: 1px solid #9e9e9e;
	}

	input[type=search]:focus:not(.browser-default) {
		border-bottom: 1px solid $input-focus-color;
		box-shadow: 0 1px 0 0 $input-focus-color;
	}

	&__submit {
		cursor: pointer;
	}
}

ul.sidenav__navigation {
	padding-top: rem(20px);
	padding-left: rem(20px);
	padding-bottom: rem(20px);
	letter-spacing: $default-letter-spacing;
	position: relative;
	top: rem(112px);
	height: calc(100% - 170px);
	overflow-y: auto;

	@include media-breakpoint-down(sm) {
		top: rem(128px);
	}

	> li {
		padding-top: rem(30px);

		&:first-child {
			padding-top: 0;
		}

		> a {
			font-weight: bold;
			padding: rem(10px);
		}
	}

	a.navi-link-external {
		&:after {
			content: "\F496";
			font-family: 'Material Design Icons', serif;
			margin-left: rem(10px);
		}
	}


	li.active {
		background: color("shades", "white");
	}

	li > a {
		font-size: rem(28px);
	}

	> li.active > a {
		color: $primary-color;
	}

	ul li {
		padding-left: rem(20px);

		&.active > a {
			color: $primary-color;
		}
	}

	ul li > a {
		font-size: rem(21px);
		font-weight: normal;
		padding: rem(10px);
	}
}

