/// Carousel - Radiosparbox, Galerie, etc

.full {
	background: #fff;
	border: 1px solid #fff;
}

.z-depth-1 {
	.full {
		box-shadow: inset 0 15px 15px -12px rgba(0,0,0,0.1),
		inset 0 -15px 15px -12px rgba(0,0,0,0.1);
	}
}

.carousel-slider .card-title{
	opacity: 0;
}
.carousel-slider .card:hover .card-title {
	opacity: 1;
	margin: 0;
}

// Carousel with visible Captions
.teaser .card {
	background: $transparent-layer-color;
	color: #fff;
}

/// Carousel gallery
.carousel-slider--gallery {
	// fix hover transformation overflow issue
	.slide {
		padding: rem(0 6px);
	}
}

/// Carousel Slider Webchannels
.carousel-slider--webchannels {
	z-index: 1;
	background: white;

	@include media-breakpoint-up(m) {
		background: none;
	}
	
	.slide {
		padding-top: rem(15px);
	}
}
