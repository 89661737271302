.icon--stack {
	&--mobile {
		@include media-breakpoint-down(m) {
			position: relative;

			.is--stacked {
				position: absolute;
				top: rem(6px);
				left: rem(-5px);
			}

			.nav-icon-item & {
				height: 73px;
				line-height: 73px;
				display: flex;
				vertical-align: middle;
				align-items: center;
			}
		}
	}
}