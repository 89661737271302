// Fonts, fonts, fonts...
// We only support modern browsers with WOFF and WOFF2

@font-face {
	font-family: 'Gudea';
	src: url('../../fonts/Gudea/Gudea-Regular.woff2') format('woff2'), url('../../fonts/Gudea/Gudea-Regular.woff') format('woff');
}

@font-face {
	font-family: 'Gudea';
	src: url('../../fonts/Gudea/Gudea-Bold.woff2') format('woff2'), url('../../fonts/Gudea/Gudea-Bold.woff') format('woff');
	font-weight: 500;
}

@font-face {
	font-family: 'Gudea';
	src: url('../../fonts/Gudea/Gudea-Italic.woff2') format('woff2'), url('../../fonts/Gudea/Gudea-Italic.woff') format('woff');
	font-style: italic;
}

@font-face {
	font-family: 'Heebo';
	src: url('../../fonts/Heebo/Heebo-Regular.woff2') format('woff2'), url('../../fonts/Heebo/Heebo-Regular.woff') format('woff');
}

@font-face {
	font-family: 'Heebo';
	src: url('../../fonts/Heebo/Heebo-Bold.woff2') format('woff2'), url('../../fonts/Heebo/Heebo-Bold.woff') format('woff');
	font-weight: 500;
}

/* these code and font were pulled from Google's CDN in order to prevent the HTTP requests to Google */
/* latin-ext */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 400;
	src: url('../../fonts/Lato/Lato.woff2') format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 400;
	src: url('../../fonts/Lato/Lato.woff2') format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 400;
	src: local(''),
	url('../../fonts/Lato/lato-v23-latin-regular.woff2') format('woff2'),
	url('../../fonts/Lato/lato-v23-latin-regular.woff') format('woff');
}

@font-face {
	font-family: 'Roboto';
	src: url('../../fonts/Roboto/Roboto-Regular.woff2') format('woff2'), url('../../fonts/Roboto/Roboto-Regular.woff') format('woff');
}

@font-face {
	font-family: 'Roboto';
	src: url('../../fonts/Roboto/Roboto-Bold.woff2') format('woff2'), url('../../fonts/Roboto/Roboto-Bold.woff') format('woff');
	font-weight: 500;
}

@font-face {
	font-family: 'Roboto';
	src: url('../../fonts/Roboto/Roboto-Italic.woff2') format('woff2'), url('../../fonts/Roboto/Roboto-Italic.woff') format('woff');
	font-style: italic;
}

@font-face {
	font-family: 'Nunito Sans';
	src: url('../../fonts/Nunito_Sans/NunitoSans-Regular.woff2') format('woff2'), url('../../fonts/Nunito_Sans/NunitoSans-Regular.woff') format('woff');
}

@font-face {
	font-family: 'Nunito Sans';
	src: url('../../fonts/Nunito_Sans/NunitoSans-Bold.woff2') format('woff2'), url('../../fonts/Nunito_Sans/NunitoSans-Bold.woff') format('woff');
	font-weight: 500;
}

@font-face {
	font-family: 'Nunito Sans';
	src: url('../../fonts/Nunito_Sans/NunitoSans-Italic.woff2') format('woff2'), url('../../fonts/Nunito_Sans/NunitoSans-Italic.woff') format('woff');
	font-style: italic;
}

@font-face {
	font-family: 'Roboto Condensed';
	src: url('../../fonts/Roboto_Condensed/RobotoCondensed-Regular.woff2') format('woff2'), url('../../fonts/Roboto_Condensed/RobotoCondensed-Regular.woff') format('woff');
}

@font-face {
	font-family: 'Roboto Condensed';
	src: url('../../fonts/Roboto_Condensed/RobotoCondensed-Bold.woff2') format('woff2'), url('../../fonts/Roboto_Condensed/RobotoCondensed-Bold.woff') format('woff');
	font-weight: 500;
}

@font-face {
	font-family: 'Rubik';
	src: url('../../fonts/Rubik/Rubik-Regular.woff2') format('woff2'), url('../../fonts/Rubik/Rubik-Regular.woff') format('woff');
}

@font-face {
	font-family: 'Rubik';
	src: url('../../fonts/Rubik/Rubik-Bold.woff2') format('woff2'), url('../../fonts/Rubik/Rubik-Bold.woff') format('woff');
	font-weight: 500;
}

@font-face {
	font-family: 'Rubik';
	src: url('../../fonts/Rubik/Rubik-Italic.woff2') format('woff2'), url('../../fonts/Rubik/Rubik-Italic.woff') format('woff');
	font-style: italic;
}

@font-face {
	font-family: "Futura Bold";
	src: url('../../fonts/Futura/futura-bold.woff2') format('woff2'), url('../../fonts/Futura/futura-bold.woff') format('woff');
}

@font-face {
	font-family: "Futura Medium";
	src: url('../../fonts/Futura/futura-medium.woff2') format('woff2'), url('../../fonts/Futura/futura-medium.woff') format('woff');
}

@font-face {
	font-family: "Futura Extra Bold";
	src: url('../../fonts/Futura/futura-extra-bold.woff2') format('woff2'), url('../../fonts/Futura/futura-extra-bold.woff') format('woff');
}

// Encode Sans - Regular, Bold, Black (PFD)

// Encode Sans Regular
@font-face {
	font-family: 'Encode Sans';
	font-style: normal;
	font-weight: 400;
	src: url('../../fonts/EncodeSans/encode-sans-v3-latin-regular.woff2') format('woff2'),
	url('../../fonts/EncodeSans/encode-sans-v3-latin-regular.woff') format('woff');
}

// Encode Sans Bold
@font-face {
	font-family: 'Encode Sans';
	font-style: normal;
	font-weight: 700;
	src: url('../../fonts/EncodeSans/encode-sans-v3-latin-700.woff2') format('woff2'),
	url('../../fonts/EncodeSans/encode-sans-v3-latin-700.woff') format('woff');
}

// Encode Sans Black
@font-face {
	font-family: 'Encode Sans';
	font-style: normal;
	font-weight: 900;
	src: url('../../fonts/EncodeSans/encode-sans-v3-latin-900.woff2') format('woff2'),
	url('../../fonts/EncodeSans/encode-sans-v3-latin-900.woff') format('woff');
}

// Montserrat (Test for PFD)

/* montserrat-regular - latin */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	src: url('../../fonts/Montserrat/montserrat-v12-latin-regular.woff2') format('woff2'),
	url('../../fonts/Montserrat/montserrat-v12-latin-regular.woff') format('woff');
}

/* montserrat-700 - latin */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 700;
	src: url('../../fonts/Montserrat/montserrat-v12-latin-700.woff2') format('woff2'),
	url('../../fonts/Montserrat/montserrat-v12-latin-700.woff') format('woff');
}

/* montserrat-800 - latin */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 800;
	src: url('../../fonts/Montserrat/montserrat-v12-latin-800.woff2') format('woff2'),
	url('../../fonts/Montserrat/montserrat-v12-latin-800.woff') format('woff');
}

/* montserrat-900 - latin */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 900;
	src: url('../../fonts/Montserrat/montserrat-v12-latin-900.woff2') format('woff2'),
	url('../../fonts/Montserrat/montserrat-v12-latin-900.woff') format('woff');
}