// Customize Material Design icons font size helper to use rem instead of px
// Original mixin: node_modules/@mdi/font/scss/_extras.scss

$mdi-sizes: 2 3 4 5 6;
@each $mdi-size in $mdi-sizes {
	.#{$mdi-css-prefix}-#{$mdi-size}x {
		&.#{$mdi-css-prefix}-set,
		&.#{$mdi-css-prefix}:before {
			font-size: $mdi-size * 1rem;
		}
	}
}

/// Flexbox mixin
@mixin flex($direction: row, $align: stretch, $justify: flex-start, $wrap: nowrap) {
	display: flex;
	flex-direction: $direction;
	align-items: $align;
	justify-content: $justify;
	flex-wrap: $wrap;
}

/// Z-Depth helper mixin
/// Set z-depth-level on body element and append class 'z-depth' to child elements which should be affected
$z-depths: 1 2 3 4 5;

@each $z-depth in $z-depths {
	.z-depth-#{$z-depth} {
		.z-depth {
			@extend .z-depth-#{$z-depth};
		}
	}
}

// Change link color (smooth animation) on mouseover - use it on the link tag!
@mixin changeLinkColor($width: 100%, $hover-color: $secondary-color) {
	position: relative;
	transition: all .5s ease;

	&:after {
		content: '';
		border-bottom: 1px solid $hover-color;
		position: absolute;
		bottom: 0;
		right: 0;
		width: $width;

		opacity: 0;
	}

	&:hover,
	&:focus {
		color: $hover-color;

		&:after {
			opacity: 1;
		}
	}
}

