// Keyframe animations

@keyframes shake {
	10%, 90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%, 80% {
		transform: translate3d(2px, 0, 0);
	}

	30%, 50%, 70% {
		transform: translate3d(-4px, 0, 0);
	}

	40%, 60% {
		transform: translate3d(4px, 0, 0);
	}
}

// Image zoom effect on mouseover
.zoom-img {
	transition: all 0.3s;

	&__wrapper {
		overflow: hidden;
		margin-bottom: 1rem;
	}

	&:hover,
	&:focus {
		transform: scale(1.1);
	}
}


@keyframes pulse {
    70% {
        box-shadow: 0 0 0 25px rgba(color("shades" , "white"), 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(color("shades" , "white"), 0);
    }
}

// CSS loader animation
// https://projects.lukehaas.me/css-loaders/

.loader,
.loader::after {
	border-radius: 50%;
	width: 1.25rem;
	height: 1.25rem;
}

.loader {
	position: relative;
	text-indent: -9999em;
	border: .15rem solid rgba(color("shades" , "white"), 0.2);
	border-left: .15rem solid color("shades" , "white");
	transform: translateZ(0);
	animation: loader 1.1s infinite linear;
}

@keyframes loader {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

// Zoom in animiation (i.e. for pfd buttons)
%zoom-in {
	&:hover,
	&:focus {
		transform: scale(1.1);
		transition: all .1s ease-in-out;
	}
}

// Blink animation (used for pfd header to switch moderators and webplayer)
@keyframes blink {
	0% { opacity: 0; visibility: hidden;}

	10% { opacity: 1; visibility: visible;}

	50% { opacity: 1; visibility: visible;}

	60% { opacity: 0; visibility: hidden;}

	100% { opacity: 0; visibility: hidden;}
}
