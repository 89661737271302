// Sponsored article scss

.sponsored-article {

	&__wrapper {
		@include media-breakpoint-down(m) {
			flex-direction: column-reverse !important;
		}
	}

	&__table-wrapper {
		position: relative;
		min-height: 100%;
		max-height: 100%;
		height: 100%;
		width: 100%;
	}

	&__table-container {
		position: absolute;
		overflow-y: scroll; 
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;

		@include media-breakpoint-down(m) {
			position: relative;
			overflow-y: auto;	
		}
	}	

	.collection {
		box-sizing: border-box;
		background: #FFFFFF;
		min-height: 100%;
		margin: 0;
	
		&-block {
			margin-top: 0.75rem;

			p {
				line-height: 1.2rem;
				margin: 0;
			}
		}
	}

	&__map {
		// FOR PLACEHOLDER !!!
		img {
			display: block;
			margin: 0;
		}
	}

	&__load-more {
		display: none;
		margin-top: 1rem;

		@include media-breakpoint-down(m) {
			display: block;
		}

		a {
			background: $primary-color;
		
			&:hover {
				background: $primary-color;
			}
		}
	}
}