// z index
$nav_z-index: 1000002;
$nav_z-index-side-nav: 1000006;
$nav_z-index-overlay: 1000005;
$nav_z-index-upper: 1000004;
$nav_z-index-lower: 1000003;


// height variables
$nav_l-bar-height: 15.5rem;
$nav_s-bar-height: 4.5rem;

// reset materialize styles
.nav {
	z-index: $nav_z-index;
	line-height: inherit;
	position: relative;
	background: none;
	height: auto;
}

// nav mobile/desktop switch
.nav {
	.nav-mobile {
		display: none;
	}
	@include media-breakpoint-down(m) {
		.nav-mobile {
			display: block;
		}
		.nav-desktop {
			display: none;
		}
	}
}

// nav mobile/desktop shared styles
.nav .nav-desktop, .nav .nav-mobile {
	&__upper {
		position: relative;
		z-index: $nav_z-index-upper;
	}
	&__lower {
		position: relative;
		z-index: $nav_z-index-lower;
		background: $primary-color;
		min-height: $nav_s-bar-height;
		max-height: $nav_s-bar-height;
		height: $nav_s-bar-height;
	}
	.container {
		position: relative;
		padding-right: 0.75rem;
		padding-left: 0.75rem;
		height: 100%;
	}
}

// nav desktop styles
.nav .nav-desktop {	
	&__upper {
		&__content {
			position: relative;
			max-height: $nav_l-bar-height;
			min-height: $nav_l-bar-height;
			height: $nav_l-bar-height;
			background-color: color("shades", "white");
			background-position: center center !important;
			background-size: cover !important;
		}
	}

	&__lower {
		.container {
			@include flex(row,center,space-between,nowrap);
			position: relative;
		}

		&__left, &__right {
			@include flex(row,center,center,nowrap);
			height: 100%;
		}
	}
}

// nav mobile styles
.nav .nav-mobile {
	&__upper {
		@include flex(row,center,space-between,nowrap);
		background: $primary-color;
		min-height: $nav_s-bar-height;
		max-height: $nav_s-bar-height;
		height: $nav_s-bar-height;
		padding-right: 0.75rem;
		padding-left: 0.75rem;

		&__left,&__right {
			@include flex(row,center,center,nowrap);
			height: 100%;
		}

		.nav-info-icon {
			i, .mdi {
				line-height: 32px;
				font-size: 32px;
				height: 32px;
				width: 32px;
			}
		}
	}

	&__lower {
		@include flex(row,center,space-between,nowrap);
		transition: margin-top 0.25s;
		padding-left: 0.75rem;
		position: relative;

		&__left, &__right {
			@include flex(row,center,center,nowrap);
			height: 100%;
		}

		&__right {
			.nav-info-icon a {
				margin-top: 5px;
			}
		}
	}
}

// nav logo styles
.nav .nav-logo {
	@include flex(row,center,flex-start,nowrap);
	display: inline-flex;
	margin-right: 1rem;
	height: 100%;

	a {
		display: block;
		line-height: 0;
		margin: 0;
	}

	&--large img {
		height: rem(140px);
	}

	&--small img {
		height: rem(54px);
	}
}

// nav weather
.nav .nav-weather {
	.icon-stack {
		padding: 0;
		margin: 0;

		&__wrapper {
			display: block;
			height: 36px;
			padding: 0;
			margin: 0;
		}
	}
}

// nav info icon
.nav .nav-info-icon {
	cursor: pointer;
	@include flex(row,center,center,wrap);
	padding: 0.25rem 0.5rem 0 0.5rem;
	height: 100%;

	&:hover {
		background: rgba(255, 255, 255, 0.25);
	}
	a {
		@include flex(column,center,center,wrap);
		position: relative;
	}
	span:not(.badge) {
		display: block;

		@include media-breakpoint-down(m) {
			display: none;
		}
	}
	.icon-stack, .icon-stack__wrapper {
		display: block !important;
	}
	i,.mdi,.icon-stack i  {
		color: color("shades", "white");
		line-height: 36px;
		font-size: 36px;
		height: 36px;
		width: 36px;
		padding: 0;
		margin: 0;

		@include media-breakpoint-up(md) {
			line-height: 42px;
			font-size: 42px;
			height: 42px;
			width: 42px;
		}
	}
	.badge {
		white-space: nowrap;
		position: absolute;
		left: -25px;
		top: -10px;
		z-index: 5;

		@include media-breakpoint-up(md) {
			width: 2rem;
			height: 2rem;
			line-height: 2rem !important;
			top: rem(-15px);
		}
	}
}

// nav playlist
.nav .nav-desktop .nav-playlist {
	@media (max-width: 880px) {
		.webradio-title-song {
			max-width: 11rem !important;
		}
	}
}

// nav web channels
.nav .nav-web-channels {
	background: color("shades", "white");
}

// nav web channels img 
.nav .nav-web-channels .carousel-slider__image {
	max-width: 110px;

	img {
		max-width: 110px;
	}
}

// nav side nav toggle 
.nav .nav-side-nav-toggle {
	@include flex(row,center,center,nowrap);
	height: 100%;
	
	.sidenav-trigger {
		margin-left: 0.25rem;
		margin-right: 0.25rem;
	}
	i,a {
		line-height: 2.75rem;
		font-size: 2.75rem;
		height: 2.75rem;
	}
}

// nav search
.nav .nav-search {
	.nav-search-bar {
		background: $primary-color;
		@include flex(row,center,center,nowrap);
		position: absolute;
		padding-right: 0.75rem;
		padding-left: 0.75rem;
		transition: 0.25s;
		height: 100%;
		width: 100%;
		z-index: 10;
		left: 0;
		top: 0;

		form {
			@include flex(row,center,space-between,nowrap);
			height: 100%;
			width: 100%;

			.nav-search-bar__text {
				border-color: color("shades", "white");
				color: color("shades", "white");
			}
			.nav-search-bar__submit {
				background: none;
				border: none;
				cursor: pointer;
			}
		}
	}
}

// nav claim
.nav .nav-claim {
	position: absolute;
	top: calc(50% - 60px);
	height: 120px;
	right: 2rem;

	img {
		width: auto;
		display: block;
		height: 100%;
	}
}

// IVW Logo nav
.nav .nav-ivw {
	margin: -7px 8px 0 0;
	height: 30px;

	@include media-breakpoint-up(m) {
		margin-right: 3rem;
		margin-top: 0;
		height: 35px;
	}

	&__image {
		max-height: 100%;
	}
}

// Quantyoo Login
.nav .nav-login .mdi-account {
	width: 2rem;
	height: 3rem;
	line-height: 3rem;
	font-size: 2.1875rem;
}

// side nav
.nav-side-nav {
	z-index: $nav_z-index-side-nav;
	position: relative;

	.sidenav {
		padding-top: 0;
	}
}

// side nav overlay
.sidenav-overlay {
	z-index: $nav_z-index-overlay !important;
}