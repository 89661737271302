//
// Import mixins
//
@import "../helpers/mixins";

// Grid variables
$grid-columns: 12 !default;
$grid-gutter-width: 10px !default;
$container-max-width: 80rem !default;
$col-base-class: 'col' !default;
$grid-breakpoints: (
		'sm': 36em,
		'm': 48em,
		'l': 62em,
		'xl': 75em,
		'xxl': 80em
) !default;
$breakpoint-name: s !default;

.container {
	margin: 0 auto;
	max-width: $container-max-width;
	width: 100%;
}

%row {
	@include flex(row, stretch, flex-start, wrap);
	box-sizing: border-box;
}

.row {
	@extend %row;
}

.#{$col-base-class} {
	padding-right: $grid-gutter-width;
	padding-left: $grid-gutter-width;
	flex-grow: 1;
	flex-basis: 0;
	max-width: 100%;
}

@for $i from 1 through $grid-columns {
	.#{$breakpoint-name}#{$i} {
		flex-basis: 100% / $grid-columns * $i;
		max-width: 100% / $grid-columns * $i;
	}
}

@for $i from 0 through $grid-columns {
	.offset-#{$breakpoint-name}#{$i} {
		@if $i == 0 {
			margin-left: 0;
		} @else {
			margin-left: 100% / $grid-columns * $i;
		}
	}
}

// Vertical spacing between columns
//.row:not(.no-row-space) > [class*="#{$col-base-class}"] + [class*="#{$col-base-class}"] {
//	margin-top: 1.25rem;
//}

// For each breakpoint, define the maximum width of the container in a media query
@each $breakpoint in $grid-breakpoints {
	$breakpoint-name: nth($breakpoint, 1);
	$breakpoint-size: nth($breakpoint, 2);

	@for $i from 1 through $grid-columns {
		@media screen and(min-width: $breakpoint-size) {
			.#{$breakpoint-name}#{$i} {
				flex-basis: 100% / $grid-columns * $i;
				max-width: 100% / $grid-columns * $i;
			}
		}
	}

	@for $i from 0 through $grid-columns {
		@media screen and(min-width: $breakpoint-size) {
			.offset-#{$breakpoint-name}#{$i} {
				@if $i == 0 {
					margin-left: 0;
				} @else {
					margin-left: 100% / $grid-columns * $i;
				}
			}
		}
	}

	@media screen and (min-width: $breakpoint-size) {
		//.row [class*="#{$breakpoint-name}"] + [class*="#{$breakpoint-name}"] {
		//	margin-top: 0;
		//}
	}
}

// Helper mixins etc...
@mixin createColumn($breakpoint-name, $columns) {
	@extend .#{$col-base-class};

	@if $breakpoint-name == 's' {
		flex-basis: 100% / $grid-columns * $columns;
		max-width: 100% / $grid-columns * $columns;
	} @else {
		@media only screen and (min-width: map-get($grid-breakpoints, $breakpoint-name)) {
			flex-basis: 100% / $grid-columns * $columns;
			max-width: 100% / $grid-columns * $columns;
		}
	}
}

/// Creates a simple grid in the parent div
/// @param {string} $breakpoint-name - choose a breakpoint name from $grid-breakpoints map
/// @param {number} $columns - number how many columns you need for your grid
@mixin createGrid($breakpoint-name, $columns) {
	@extend %row;

	> * {
		@include createColumn($breakpoint-name, $columns);
	}
}

/// Makes the @content apply to the given breakpoint and wider.
/// @param {string} $breakpoint-name - choose a min-width breakpoint name from $grid-breakpoints map
@mixin media-breakpoint-up($breakpoint-name, $breakpoints: $grid-breakpoints) {
	$min: map-get($breakpoints, $breakpoint-name);
	@if $min {
		@media (min-width: $min) {
			@content;
		}
	} @else {
		@content;
	}
}

/// Makes the @content apply to the given breakpoint and narrower.
/// TODO: Calculate -1px to media-breakpoint-down
/// @param {string} $breakpoint-name - choose a max-width breakpoint name from $grid-breakpoints map
@mixin media-breakpoint-down($breakpoint-name, $breakpoints: $grid-breakpoints) {
	$max: map-get($breakpoints, $breakpoint-name);
	@if $max {
		@media (max-width: $max) {
			@content;
		}
	} @else {
		@content;
	}
}

/// Makes the @content apply between the min and max breakpoints
/// @param {string} $lower - choose the min-width breakpoint name from $grid-breakpoints map
/// @param {string} $upper - choose the max-width breakpoint name from $grid-breakpoints map
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
	$min: map-get($breakpoints, $lower);
	$max: map-get($breakpoints, $upper);

	@if $min != null and $max != null {
		@media (min-width: $min) and (max-width: $max) {
			@content;
		}
	} @else if $max == null {
		@include media-breakpoint-up($lower, $breakpoints) {
			@content;
		}
	} @else if $min == null {
		@include media-breakpoint-down($upper, $breakpoints) {
			@content;
		}
	}
}

