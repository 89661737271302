/* use this file instead of adding css in the rnrw_elements repository */
rnrw-elements-channels-slider figure {
	cursor: pointer;
}

/* allow slideDown transition in webcomponent */
rnrw-elements-channels-pfd .transition-wrapper {
	background-color: white;
	z-index: 1;
}

/* need position relative for the transition effect */
rnrw-elements-channels-pfd .nav-web-channels {
	position: relative;
}

/* googlemap actual map size */
rnrw-elements-googlemap .rnrw-elements-googlemap-map {
	height: 600px;
	width: 100%;
}

/* infobox-contact submit */
rnrw-elements-infobox-contact button[type="submit"] {
	display: inline-block;
}

/* infobox-contact loader */
rnrw-elements-infobox-contact .loader {
	top: .5em;
}

/* infobox-moderator (pfd) */
rnrw-elements-infobox-moderator .elements-moderator-programname, rnrw-elements-infobox-moderator .elements-moderator-moderatorname {
	display: block;
}

rnrw-elements-infobox-moderator .elements-moderator-programname {
	font-size: 1rem;
}

rnrw-elements-infobox-moderator .elements-moderator-moderatorname {
	font-size: .9rem;
}

/* infobox-onair */
rnrw-elements-infobox-onair img.responsive-img {
	object-fit: initial;
}

/* traffic-dispatch */
rnrw-elements-traffic-dispatch input[type="submit"] {
	cursor: pointer;
	height: 100%;
	width: 100%;
}

/* playlist */
rnrw-elements-playlist[type="search"] {
	-webkit-appearance: initial !important;
	outline-offset: initial !important;
}

/* infobox-container */
rnrw-elements-infobox-iconbar {
	display: block;
}

/* rnrw-elements-form */
rnrw-elements-form .row {
	margin-bottom: 20px;
}

rnrw-elements-form-checkbox p {
    margin-top: 3px !important;
	margin-left: 7px !important;
}
