// Global playlist styles
.playlist__items__wrap {
	margin-bottom: rem(20px);
}

.playlist-time {
	display: block;
}

.playlist__song__label {
	display: block;
	padding: .5rem 0;

	@include media-breakpoint-up(m) {
		display: inline-block;
		padding: 0;
	}
}

.playlist__song__title {
	text-transform: uppercase;
	line-height: 1.25;
}

.playlist__song__artist,
.playlist__time {
	padding-left: .25rem;
}

// Full playlist styles
.playlist__items {
	.playlist__song {
		display: flex;
		padding: .5rem;
	}

	.playlist__song__infos {
		padding: 0;
		margin: 0 0 0 calc(3.75rem + 1rem); // cover width + cover margin
	}

	.playlist__cover + .playlist__song__infos {
		margin: 0;
	}

	.playlist__song__artist,
	.playlist__time {
		display: block;
		line-height: 1.5;
	}

	.playlist__cover {
		margin-right: 1rem;
		max-width: 3.75rem;
		max-height: 3.75rem;
	}
}

.playlist__affiliate__icon {
	font-size: rem(20px);
}

.buttons--small {
	.playlist__affiliate__icon {
		vertical-align: text-bottom;
	}
}

.playlist__affiliate__link {
	margin: rem(0 3px);
}

// Small infobox styles
.playlist--small {
	.playlist__affiliate {
		padding: rem(10px 0 0 80px);

		@include media-breakpoint-between(sm, l) {
			flex: 0 0 55%;
		}

		@include media-breakpoint-up(xl) {
			float: right;
			margin-top: rem(-45px);
		}
	}

	.playlist__song__infos {
		@include media-breakpoint-between(sm, l) {
			flex: 0 0 45%;
		}
	}

	.playlist__song {
		display: block;

		@include media-breakpoint-between(sm, l) {
			@include flex(row, center, space-between, nowrap);
		}
	}

}

// Timeline styles
.playlist--timeline {
	.playlist__song {
		align-items: center;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 0;
		margin: 0;

		@include media-breakpoint-up(m) {
			flex-wrap: nowrap;
		}
	}

	.playlist__song__infos {
		padding: rem(20px 0);
		flex: 60% 0 0;

		@include media-breakpoint-up(sm) {
			flex: 78% 0 0;
		}

		@include media-breakpoint-up(m) {
			flex: 50% 0 0;
		}
	}

	.playlist__song__title {
		font-weight: 500;
	}

	.playlist__cover {
		max-width: 6.25rem;
		max-height: 6.25rem;
		margin: rem(0 15px 0 0);
		width: auto !important;
	}

	.playlist__affiliate {
		padding: rem(0 0 10px 130px);
		margin-left: auto;

		@include media-breakpoint-up(m) {
			padding: rem(0 10px);
		}
	}
}

// Playlist filters / search
.playlist__search {
	margin-top: rem(30px);
}
