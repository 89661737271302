// Custom Typography
.date {
	display: block;
	color: lighten($off-black, 25%);
}

// full width+height link
.cover-link-container {
	position: relative;
}

.cover-link {
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	transition: all 0.3s;

	&:not(.cover-link--invisible) {
		&:hover {
			background-color: rgba(0, 0, 0, 0.1);
			transition: all 0.3s;
		}
	}
}

// Share Buttons
.fixed-action-btn .btn-floating {
	border-radius: 10px;
	background: $primary-color;
}

// Cookie Modal
.modal-footer {
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

// "floats" in valign-wrapper (flex)
.valign-wrapper {
	> .right {
		margin-left: auto;
	}
	> .left {
		margin-right: auto;
	}
}

// helper class for open modals (background)
.blurred {
	position: relative;
	&:after {
		display: block;
		content: '';
		background: #000;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		opacity: 0.3;
	}
	> * {
		-webkit-filter: blur(5px);
		-moz-filter: blur(5px);
		-o-filter: blur(5px);
		-ms-filter: blur(5px);
		filter: blur(5px);
	}
}

// Card changes
.card .card-content .card-title {
	margin-top: 0;
	line-height: inherit;
}

.sidebar .card .card-image .card-title {
	margin: 0;
}

.card-action__link {
	display: block;
}

// Breaker
.container.breaker {
	@include media-breakpoint-down(l) {
		width: 100%;
		max-width: 100%;
	}
}

// floating images in cards etc
.teaser-float {
	max-width: 33%;
}

// moving image outside of text-div
@include media-breakpoint-up(l) {
	.pull-left {
		float: left;
		padding: 0 0.75rem; //same as .col padding
		margin-left: -16.66667%;
		margin-top: 1em; //same as p
		img {
			margin: 0 0.75rem // same as .col padding
		}
	}
}

// button styling
.download:hover {
	text-decoration: none;
}

// Twitter timeline
.timeline-Widget--edge {
	border-radius: 0;
}

.iframe-video {
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	height: 0;
}

.iframe-video iframe {
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	position: absolute;
}

// Images
figure {
	margin: 0;
}

// Image grid

.image-grid {
	@include createGrid(s, 6);
	@include createGrid(m, 4);

	&__item {
		margin-bottom: rem(10px);
	}
}

