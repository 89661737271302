// Flexbox visibility helper classes

.show-flex-on-medium-and-up {
	@include media-breakpoint-up(sm) {
		display: flex !important;
	}
}

// Responsive visibility helper classes
// TODO: Refactor helper classes (many classes from _global.scss in materialize, they load 2x, 3x and more
.hide-on-small-only, .hide-on-small-and-down {
	@include media-breakpoint-down(sm) {
		display: none !important;
	}
}

.hide-on-med-and-down {
	@include media-breakpoint-down(l) {
		display: none !important;
	}
}

.hide-on-small-med-and-up {
	@include media-breakpoint-up(m) {
		display: none !important;
	}
}

.hide-on-small-med-and-down {
	@include media-breakpoint-down(m) {
		display: none !important;
	}
}

.hide-on-med-and-up {
	@include media-breakpoint-up(sm) {
		display: none !important;
	}
}

.hide-on-med-only {
	@include media-breakpoint-between(sm, l) {
		display: none !important;
	}
}

.hide-on-large-only {
	@include media-breakpoint-up(l) {
		display: none !important;
	}
}

.show-on-large:not([style*="display:none"]):not([style*="display: none"]) {
	@include media-breakpoint-up(l) {
		display: block !important;
	}
}

.show-on-medium:not([style*="display:none"]):not([style*="display: none"]) {
	@include media-breakpoint-between(sm, l) {
		display: block !important;
	}
}

.show-on-small:not([style*="display:none"]):not([style*="display: none"]) {
	@include media-breakpoint-down(sm) {
		display: block !important;
	}
}

.show-on-medium-and-up:not([style*="display:none"]):not([style*="display: none"]) {
	@include media-breakpoint-up(sm) {
		display: block !important;
	}
}

.show-on-medium-and-down:not([style*="display:none"]):not([style*="display: none"]) {
	display: none;
	@include media-breakpoint-down(m) {
		display: block !important;
	}
}

.show-on-small-medium-and-up:not([style*="display:none"]):not([style*="display: none"]) {
	@include media-breakpoint-up(m) {
		display: block !important;
	}
}

.show-inline-on-small-only:not([style*="display:none"]):not([style*="display: none"]) {
	display: inline-block;

	@include media-breakpoint-up(m) {
		display: none;
	}
}

.show-on-large-and-down:not([style*="display:none"]):not([style*="display: none"]) {
	display: none;

	@include media-breakpoint-down(l) {
		display: block;
	}
}

.hide-on-large-and-down:not([style*="display:none"]):not([style*="display: none"]) {
	display: block;
	
	@include media-breakpoint-down(l) {
		display: none;
	}
}
.show-on-small-only:not([style*="display:none"]):not([style*="display: none"]) {
	display: none;

	@include media-breakpoint-down(sm) {
		display: block;
	}
}

// Center text on mobile
.center-on-small-only {
	@include media-breakpoint-down(sm) {
		text-align: center;
	}
}

.centered {
	@include flex(row, center, center, nowrap);
}

// Spacer classes

.padding-0 {
	padding: 0;
}

.left-on-small-medium-and-up {
	@include media-breakpoint-up(m) {
		float: left;
		padding-right: rem(25px);
	}
}

/// Collapse helper
.collapse-content {
	overflow: hidden;
	transition: max-height 0.3s ease-in-out;
	height: auto;
	max-height: rem(300px);
}

.collapsed {
	max-height: 0;
}

// Flexbox helpers
.align-baseline {
	align-items: baseline;
}

// Make arrow under an element (i.e. pinterest view)
%arrow-bottom::after {
	content: ' ';
	display: block;
	width: 0;
	height: 0;
	border-left: 30px solid transparent;
	border-right: 30px solid transparent;
	border-bottom: 20px solid rgba($body-color, .7);
	position: absolute;
	bottom: -1px;
	left: 0;
	right: 0;
	margin: 0 auto;
}