.btn-floating,
.btn-large,
.btn-small {
	box-shadow: none;
}

.z-depth-1 {
	.btn-floating,
	.btn-large,
	.btn-small {
		@extend .z-depth-1;
	}
}

/// Light grey button, i.e. for social media share buttons
.btn-light {
	background-color: color("grey", "base");
}

/// Dark grey button
.btn-dark {
	background-color: color("grey", "darken-2");

	&:hover,
	&:focus,
	&:active {
		background-color: color("grey", "darken-2");
	}
}

/// Primary button color
.btn-primary {
	background-color: $primary-color;

	&:hover,
	&:focus,
	&:active {
		background-color: $primary-color;
	}
}

/// Transparent button
.btn-transparent {
	background-color: transparent;
	box-shadow: none;

	&:hover,
	&:focus,
	&:active {
		background-color: transparent;
		box-shadow: none;
	}
}

/// Small button group
.buttons--small {
	.btn {
		width: 2rem;
		height: 2rem;
	}

	.btn__icon {
		font-size: 1.25rem;
		line-height: 2rem;
	}

	.btn__icon::before {
		vertical-align: text-bottom;
	}
}

/// Newsroom button group
.buttons-newsroom {
	display: inline-flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;

	.btn {
		align-items: center;
		display: inline-flex;
		flex-grow: 1;
		height: auto;
		justify-content: center;
		line-height: 1.25rem;
		margin: 1rem auto 0 auto;
		max-height: 4rem;
		max-width: rem(180px);
		overflow: hidden;
		padding: 1rem;
		vertical-align: middle;
		width: rem(180px);
	}
}

/// Button with icon in it
.btn--icon {
	@include flex(row, center, center, nowrap);

	&__image {
		width: rem(30px);
		height: rem(30px);
		padding-right: rem(10px);
	}
}

// Button with loader icon
.btn--loader {
	@include flex(row, center, space-between, nowrap);
	margin-left: .5rem;
	position: relative;

	&__text {
		padding-right: .5rem;
	}
}

.fixed-action-btn ul {
	bottom: 56px !important;
}   

