/// Related News items

.related-news__item {
	padding: $small-card-padding;
}

.related-news__content {
	margin-top: rem(10px);

	@include media-breakpoint-up(m) {
		display: flex;
		align-items: flex-start;
	}
}

.related-news__text {
	@include media-breakpoint-up(m) {
		margin: 0;
	}
}

.related-news__text + .related-news__image {
	@include media-breakpoint-up(m) {
		max-width: 140px;
		min-width: 140px;
		order: -1;
		margin-right: rem(10px);
	}
}

.related-news__header {
	margin-bottom: rem(10px);
}

.news__title {
	margin: 0;
}

/// Small version
.related-news--small {
	.related-news__text + .related-news__image {
		@include media-breakpoint-up(m) {
			max-width: 20%;
		}
	}
}