// // Floating action buttons scss


// .btn-action {
// 	background: $primary-color;
// 	border-radius: 10px;
// 	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16) !important;

// 	&:hover {
// 		background: darken($primary-color, 5);
// 	}
// }

// .with-scroll-btn {
// 	bottom: 102px;
// }

// .floating-scroll-button {
// 	position: fixed;
//     z-index: 997;
//     bottom: 102px;
//     right: 23px;
// }

// .floating-ivw-logo {
// 	background: $background-light;
// 	position: fixed;
// 	z-index: 1;
// 	border-radius: 10px;
// 	padding-right: 2px;
// 	padding-left: 2px;
// 	right: 23px;
// 	bottom: 23px;
// 	height: 56px;
// 	width: 56px;
// 	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);

// 	a {
// 		height: 100%;
// 		width: 100%;
// 		img {
// 			display: block;
// 			object-fit: contain;
// 			height: 100%;
// 			width: 100%;
// 		}
// 	}
// }


.floating-action-buttons {
	flex-direction: column;
	position: fixed;
	display: flex;
	z-index: 9999;
	right: 20px;
	bottom: 0px;
	width: 56px;
}
.floating-action-buttons .fab {
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	background: $primary-color;
	border-radius: 10px;
	margin-bottom: 20px;
	height: 56px;
	width: 56px;
	cursor: pointer;
}
.floating-action-buttons .fab__social .fixed-action-btn {
	position: relative !important;
	bottom: auto !important;
	left: auto !important;
	right: auto !important;
	top: auto !important;
	padding: 0 !important;
	margin: 0 !important;
}

.fab__social {
	box-shadow: none !important;
}

.fab__scroll {
	color: white;
	justify-content: center;
	align-items: center;
	display: flex;

	.mdi {
		font-size: 2rem;
	}
}

.fab__ivw {
	background: white !important;
	
	a {
		height: 100%;
		width: 100%;
		img {
			object-fit: contain;
			display: block;
			height: 80%;
			width: 80%;
			margin: 10%;
		}
	}
}

.fab__show_on_scroll {
	display: none;
}