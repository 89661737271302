.file__item {
	padding: 1rem 0;

	.col:first-child {
		text-align: center;
	}
}

.file__icon {
	color: color('grey', 'darken-1');
}

.file__item__title {
	font-weight: 500;
	font-size: rem(18px);
}

.file__item__filename {
	font-size: rem(14px);
}

.file__item__desc {
	font-size: rem(14px);
	line-height: 1;
	padding-top: rem(10px);
}