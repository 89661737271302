.team-cards {
	.row {
		justify-content: flex-start;
	}

	.row {
		align-items: stretch;
	}
}

.team-card {
	box-sizing: border-box;
	height: calc(100% - 16px);
	
	.foreground {
		text-align: center;
		position: absolute;
		overflow: hidden;
		height: 100%;
		width: 100%;
		bottom: 0;
		left: 0;

		img {
			display: inline-block;
			height: 100%;
			width: auto;
		}
	}

	&__team {
		text-align: center;
		line-height: 1.5rem;
		font-size: 0.75rem;
		color: $off-black;
	}

	&__name {
		text-align: center;
		font-weight: bold;
		color: $off-black;
	}

	&__tag {
		position: relative;
		text-align: center;
		line-height: 1.5rem;
		font-size: 0.75rem;
		color: $off-black;
	}
}