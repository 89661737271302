/// Basic infobox styles
.infobox {
	position: relative;
}

.infobox__container {
	flex-direction: column;
	min-height: rem(620px);

	@include media-breakpoint-up(m) {
		flex-direction: row;
		min-height: rem(475px);
	}
}

.infobox {
	background-color: $background-light;

	p {
		margin: 0;
	}

	h5 {
		margin: 0;
	}
}

.infobox--big {
	&:not(.current-program) {
		padding: 1rem;
	}

	@include media-breakpoint-up(m) {
		position: relative;
	}
}

/// Infobox Wrapper
.infobox__wrapper {
	@include flex(row,center,flex-start,wrap);
	padding: .5rem;

	@include media-breakpoint-up(m) {
		padding: 1rem;
	}
}

/// Small Infobox
.infobox--small {
	.infobox__headline {
		line-height: 3.5rem;
		flex: 0 0 15%;

		@include media-breakpoint-down(xxl) {
			margin-bottom: 0.5rem;
			flex: 0 0 100%;
		}
	}

	.infobox__wrapper {
		justify-content: flex-start;
	}
}

/// Infobox Headline
.infobox__headline {
	@include flex(row,center,flex-start);
	min-height: 100%;
}

/// Infobox Content
.infobox__content {
	@include flex(row,center,flex-start,wrap);
	position: relative;
	min-height: 100%;
	flex: 0 0 85%;

	@include media-breakpoint-down(xxl) {
		flex: 0 0 100%;
	}

	.icon-stack {
		position: relative;
		display: inline-block;
		margin-right: 5px;
		height: rem(34px);
		width: rem(34px);
		
		.icon {
			position: absolute;
			color: $primary-color;
			font-size: rem(34px);
			line-height: rem(34px);
			left: 0;
			top: 0;
		}
	}
	
	span {
		@include flex(row,center,flex-start);
		width: 150px;
		min-height: 100%;
	}
	
	.infobox__content__button {
		width: calc(100% - 300px);
		
		@include flex(row,center,flex-end,nowrap);

		a {
			@include flex(row,center,center,nowrap);
			background: $primary-color;
			height: 44px !important;
			width: 44px !important;
			position: relative;
			border-radius: 10px;
			z-index: 2;

			img {
				height: 34px;
				width: auto;
			}
		}
	}
}

.infobox__traffic {
	.traffic-tabs__headline--has-icon {
		@include flex(row, center, space-between, nowrap);
	}

	.traffic-tabs__headline--has-icon::before {
		width: rem(32px);
		margin: rem(0 5px 0 0);
	}
}











.tabs .tab {
	min-width: 0; //overwriting materialize css to adjust for icon tabs
}

.current-program__teaser {
	span {
		position: absolute;
		background-color: $transparent-layer-color;
		color: #fff;
		padding: 0 rem(3px);
	}

	.foreground {
		justify-content: center;
		text-align: center;
		position: absolute;
		overflow: hidden;
		display: flex;
		height: 100%;
		width: 100%;
		bottom: 0;
		left: 0;
	
		img {
			display: inline-block;
			height: 100%;
			width: auto;
		}
	}
}

// Current program
.current-program__info {
	padding: 1rem;
	position: relative;
	background: white;

	@include media-breakpoint-up(m) {
		background-color: $background-light;
	}
}

.current-program__title {
	margin-bottom: 0;
	margin-top: rem(10px);

	@include media-breakpoint-up(m) {
		max-width: 70%;
		margin-top: 0;
	}
}

.current-program__subtitle {
	margin-top: 0;
}

.current-program__teaser,
.current-program__teaser img {
	display: block;
	max-height: 100%;
	min-height: rem(388px);
	object-fit: cover;
}

.current-program__icons {
	@include flex(row, stretch, flex-end, nowrap);
	margin-top: -50px;

	@include media-breakpoint-up(m) {
		margin-top: 0;
		position: absolute;
		right: 4%;
		top: 10%;
	}

	.btn {
		background-color: $primary-color;
		margin: 0 0.25rem;
		white-space: nowrap;
	}
}

// Contact form
.contact__form {
	padding: 0;

	.col:nth-of-type(odd) {
		padding-left: 0;

		label {
			left: 0;
		}
	}

	.btn-send {
		box-sizing: border-box;
		width: 100%;
	}
}

.infobox__weather {
	.infobox__content {
		flex-wrap: wrap;
	}
}