.account {
  .btn--logout {
    margin-top: rem(16px);

    @include media-breakpoint-up(m) {
      margin: 0 0 0 rem(16px);
    }
  }

  &__details {
    margin-top: rem(40px);

    dd {
      margin-left: 0;
      font-weight: bold;
    }

    dl {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: minmax(40px, auto)
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(m) {
      flex-direction: row;
    }
  }
}