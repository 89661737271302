.teaser-audio-player {
    width: calc(32px + 32px + 32px + 4px + 4px);
    justify-content: space-between;
    align-items: center;
    display: flex;
    color: #5c5c5c;

    &__rewind, &__play, &__pause, &__forward {
        cursor: pointer;
        height: 32px;
        width: 32px;
    }
}

[data-teaser-audio-player="true"] {
    .teaser-audio-player__play {
        display: none;
    }
    .teaser-audio-player__pause {
        display: block;
    }
}

[data-teaser-audio-player="false"] {
    .teaser-audio-player__play {
        display: block;
    }
    .teaser-audio-player__pause {
        display: none;
    }
}

.teaser-audio-player__pos-br {
    position: absolute;
    bottom: 8px;
    right: 8px;
    z-index: 1;
}

.teaser-audio-player__background {
    background: rgba(255, 255, 255, 0.7);
    box-shadow: rgba(0, 0, 0, 0.16) 0 2px 5px 0, rgba(0, 0, 0, 0.12) 0 2px 10px 0;
    border-radius: 0.5rem;
    padding: 6px;
    bottom: 16px;
    right: 16px;
}

@media only screen and (max-width: 500px) {
    .teaser-audio-player__background {
        bottom: auto;
        top: 16px;
    }
}

.card .news-categories__content--with-player,
.card .news-teaser__content--with-player {
  padding-bottom: 44px !important;
}