.icon-stack {
	position: relative;
	display: inline-block;
	margin-right: 0.5rem;
	height: rem(42px);
	width: rem(42px);
	
	.icon {
		position: absolute;
		color: $primary-color;
		font-size: rem(42px);
		line-height: rem(42px);
		left: 0;
		top: 0;
	}
}

// Weather Info
.weather-info {
	&__box {
		padding: rem(16px);

		.col {
			display: flex;
		}
	
		&__day, &__temperature, &__data {
			white-space: nowrap;
			align-items: center;
			display: flex;
			height: 100%;
		}

		&__data i {
			margin-right: rem(6px);
		}

		&__extended {
			width: calc(100% + 20px);
			margin-right: -10px;
			margin-left: -10px;
			
			.col {
				margin-bottom: 10px;
				margin-top: 10px;

				.ext-data__wrapper {
					border-radius: $card-border-radius;
					background: rgb(245, 245, 245);
					padding: 0.5rem;
					width: 100%;
					padding: 1rem;
				}

				.ext-data {
					display: block;
					width: 100%;
				}

				.ext-heading {
					text-align: center;
				}
	
				.ext-icon {
					justify-content: center;
					align-items: center;
					display:flex;
					padding-bottom: 1rem;
					padding-top: 1rem;

					// TODO BESSER MACHEN!!!!

					.icon-stack {
						transform: scale(1.5);
					}
				}
	
				.ext-detail {
					align-items: center;
					display:flex;
					margin-top: 1rem;
					
					i {
						margin-right: 1rem;
						font-size: 1rem;
					}
				}
			} 
		}
	}
}


// Weather detail page

// .infobox__weather--detail {
// 	padding: rem(0 10px);

// 	@include media-breakpoint-up(m) {
// 		padding: rem(0 30px);
// 	}

// 	.infobox__headline {
// 		flex: 0 0 22%;

// 		@include media-breakpoint-up(m) {
// 			flex: 0 0 15%;
// 		}
// 	}

// 	.infobox__weather {
// 		min-width: 15%;
// 		justify-content: center;
// 	}

// 	.infobox__details {
// 		flex: 0 0 60%;
// 		display: flex;
// 		justify-content: space-around;

// 		@include media-breakpoint-down(sm) {
// 			font-size: rem(12px);
// 			padding-left: 10%;
// 			display: block;
// 			flex: 0 0 33%;

// 			.mdi {
// 				&::before {
// 					font-size: rem(20px);
// 				}
// 			}

// 			.infobox__weather {
// 				justify-content: flex-start;
// 			}
// 		}
// 	}
// }
