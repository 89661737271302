.timeline__item {

	@include media-breakpoint-up(m) {
		@include flex(row, center, center, nowrap);
	}
}

.timeline__datetime {
	font-weight: bold;
	position: relative;
	display: block;
	text-align: center;

	@include media-breakpoint-up(m) {
		text-align: left;
		min-width: 25%;
	}

	&::after {
		content: "";
		height: rem(15px);
		width: rem(15px);
		background-color: $off-black;
		border-radius: 50%;
		display: block;
		position: absolute;
		left: calc(50% - 5px);
		bottom: rem(-20px);

		@include media-breakpoint-up(m) {
			left: auto;
			right: 28%;
			bottom: 36%;
		}
	}
}

.timeline__time {
	display: block;
	font-size: rem(35px);
}

.timeline__date {
	display: block;
	font-size: rem(14px);
	font-weight: 500;
}

.timeline__item__content {
	width: 100%;
	position: relative;
	padding-top: rem(30px);
	padding-bottom: rem(15px);

	.card-content {
		max-width: 100% !important;
		width: 100% !important;
	}

	&::before {
		content: "";
		border-right: rem(6px) solid $off-black;
		height: rem(40px);
		display: block;
		position: absolute;
		left: 50%;
		top: rem(10px);

		@include media-breakpoint-up(m) {
			left: -10%;
			top: 50%;
			border-right: none;
			border-bottom: rem(6px) solid $off-black;
			width: rem(70px);
			height: rem(6px);
			padding-left: rem(50px);
		}
	}
}

.timeline__item__content {
	.news-teaser__content {
		padding-top: 1.5rem;
	}

	.news-teaser__item__inner {
		margin: 0;
	}
}