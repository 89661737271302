[type="radio"] + span:before,
[type="radio"] + span:after {
	width: $radio-button-size;
	height: $radio-button-size;
}

[type="radio"]:not(:checked) + span,
[type="radio"]:checked + span {
	line-height: rem(33px);
	padding-left: rem(40px);
}