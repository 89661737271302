/// Base share button styles
.share__button {
	margin: 0 .25rem;
	z-index: 5;

	&:first-child {
		margin: 0 .25rem 0 0;
	}

	&:last-child {
		margin: 0 0 0 .25rem;
	}
}

/// Big share buttons
.share--big {
	padding: rem(20px 0);

	.share__button {
		display: inline-flex;
		align-items: center;
		width: rem(50px);
		height: rem(50px);
		margin-right: rem(8px);
	}

	.share__button__icon {
		font-size: 2rem;
	}
}