html {
	line-height: 1.5;
	font-family: "Roboto", sans-serif;
	font-weight: normal;
	color: $off-black;

	// Override materialize default styles
	@media only screen and (min-width: 0) {
		font-size: 1rem;
	}

	@include media-breakpoint-up(l) {
		font-size: 1rem;
	}

	@include media-breakpoint-up(xl) {
		font-size: 1rem;
	}
}

h1, h2, h3, h4, h5, h6 {
	font-weight: 400;
	line-height: 1.1;
	font-family: "Eurostile", Roboto, sans-serif;
	letter-spacing: 0.02em;
	color: $primary-color;
}

// Header Styles
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
	font-weight: inherit;
	color: $primary-color;
}

h1 {
	line-height: 1.2;
	margin: ($h1-fontsize / 3.2) 0 ($h1-fontsize / 4.5) 0;
	font-size: $h1-fontsize-mobile;

	@include media-breakpoint-up(m) {
		font-size: $h1-fontsize;
	}
}

h2 {
	font-size: $h2-fontsize-mobile;
	line-height: 1.2;
	margin: ($h1-fontsize / 2.2) 0 ($h2-fontsize / 2.8) 0;

	@include media-breakpoint-up(m) {
		font-size: $h2-fontsize;
	}
}

h3 {
	font-size: $h3-fontsize-mobile;
	line-height: 110%;
	margin: ($h3-fontsize / 2.2) 0 ($h3-fontsize / 2.8) 0;

	@include media-breakpoint-up(m) {
		font-size: $h3-fontsize;
	}
}

h4 {
	font-size: $h4-fontsize;
	line-height: 110%;
	margin: ($h4-fontsize / 2.2) 0 ($h4-fontsize / 2.8) 0;
}

h5 {
	font-size: $h5-fontsize;
	line-height: 110%;
	margin: ($h5-fontsize / 2.2) 0 ($h5-fontsize / 2.8) 0;
}

h6 {
	font-size: $h6-fontsize;
	line-height: 110%;
	margin: ($h6-fontsize / 2.2) 0 ($h6-fontsize / 2.8) 0;
}

p {
	margin: 1.25rem 0;
}

// Text Styles
a {
	color: $link-color;
	text-decoration: none;

	&:hover {
	}

	// Gets rid of tap active state
	-webkit-tap-highlight-color: transparent;
}

em {
	font-style: italic;
}

strong {
	font-weight: 700;
}

small, .small {
	font-size: .8rem;
}

.light {
	font-weight: 300;
}

.thin {
	font-weight: 200;
}

.abstract {
	font-size: 1.2rem;
	font-style: italic;
}

ul, ol {
	line-height: 2;
}

/// Headline styles
%headline-s {
	font-weight: 400;
	font-family: "Eurostile", Roboto, sans-serif;
	letter-spacing: 0.02em;
	color: $primary-color;
	font-size: 1.25rem;
}

// article element
article {
	overflow: auto;
}

/// Article detail page
.article__details {
	line-height: 1.75;
}