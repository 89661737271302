@charset "UTF-8";

$slick-font-family: "Material Design Icons";
$slick-loader-path: "./";
$slick-arrow-color: white;
$slick-dot-color: black;
$slick-dot-color-active: $slick-dot-color;
$slick-prev-character: "\2190";
$slick-next-character: "\2192";
$slick-dot-character: "\2022";
$slick-dot-size: 6px;
$slick-opacity-default: 0.75;
$slick-opacity-on-hover: 1;
$slick-opacity-not-active: 0.25;
$slick-size: rem(80px);

/* Slider */

.slick-list {
	.slick-loading & {
		background: none;
	}
}

/* Arrows */

.slick-prev, .slick-next {
	z-index: 2;
	&:before {
		@extend .mdi;
		opacity: 1;
		color: color("grey", "lighten-3");
	}
}

.slick-prev,
.slick-next {
	position: absolute;
	display: block;
	width: auto;
	height: auto;
	line-height: 0px;
	font-size: 0px;
	cursor: pointer;
	background: transparent;
	color: transparent;
	top: 50%;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
	padding: 0;
	border: none;
	outline: none;
	&:hover, &:focus {
		outline: none;
		background: transparent;
		color: transparent;
		&:before {
			opacity: $slick-opacity-on-hover;
		}
	}
	&.slick-disabled:before {
		opacity: $slick-opacity-not-active;
	}
	&:before {
		color: color("grey", "lighten-3");
		opacity: $slick-opacity-default;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-size: $slick-size;
		line-height: $slick-size;
	}
}

.slick-prev {
	left: 0;
	[dir="rtl"] & {
		left: auto;
		right: -25px;
	}
	&:before {
		content: "\F141";
		[dir="rtl"] & {
			content: "\F142";
		}
	}
}

.slick-next {
	right: 0;

	[dir="rtl"] & {
		left: -25px;
		right: auto;
	}
	&:before {
		content: "\F142";
		[dir="rtl"] & {
			content: "\F141";
		}
	}
}

/* Dots */

.slick-dotted.slick-slider {
	margin-bottom: 30px;
}

.slick-dots {
	position: absolute;
	list-style: none;
	display: block;
	text-align: center;
	padding: 0;
	margin: 0;
	width: 100%;
	left: 0;
	bottom: rem(20px);

	li {
		position: relative;
		display: inline-block;
		height: 20px;
		width: 20px;
		margin: 0 5px;
		padding: 0;
		cursor: pointer;
		button {
			border: 0;
			background: transparent;
			display: block;
			height: 20px;
			width: 20px;
			outline: none;
			line-height: 0px;
			font-size: 0px;
			color: transparent;
			padding: 5px;
			cursor: pointer;
			&:hover, &:focus {
				outline: none;
				&:before {
					opacity: $slick-opacity-on-hover;
					border-color: darken($pagination-color, 10%);
					background: darken($pagination-color, 10%);
				}
			}
			&:before {
				position: absolute;
				top: 0;
				left: 0;
				width: 20px;
				height: 20px;
				font-size: $slick-dot-size;
				line-height: 20px;
				text-align: center;
				color: $slick-dot-color;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;

				content: ' ';
				border: rem(1px) solid darken($pagination-color, 10%);
				background: $pagination-color;
				opacity: 1;
				border-radius: 50%;
			}
		}
		&.slick-active button:before,
		&.slick-active button:hover:before {
			background: $primary-color;
			border-color: $primary-color;
			opacity: 1;
		}
	}
}

.slick-slider.slick-dotted {
	margin-bottom: rem(62px);
}
