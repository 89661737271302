span {
	&.badge {
		font-size: rem(10px);
		color: $off-black;
		padding: 0 rem(4px);
	}

	&.badge--circle {
		line-height: 2.5;
		border-radius: 50%;
		min-width: rem(28px);
		width: rem(28px);
		background-color: $background-light;
		height: rem(28px);
		letter-spacing: -1px;
		border: 1px solid $primary-color;
	}
}

nav ul a span.badge {
	position: absolute;
	margin-left: 0;
	line-height: 2.5;
	height: rem(28px);
}
