.category__title {
	color: $off-black;
}

// Category headlines (Desktop only)
.full.news-categories {
	background: color("grey", "lighten-3");
	.row {
		margin-bottom: 0;
	}
	.col {
		border-left: 1px solid color("shades", "white");
		position: relative;
		&:last-child {
			border-right: 1px solid color("shades", "white");
		}

		@extend %arrow-bottom;
	}
}

.news-categories__column {
	padding: 0;

	.col,
	.category__title {
		padding: rem(0 5px);

		@include media-breakpoint-up(l) {
			padding: rem(0 10px);
		}
	}
}

.news-categories__columns {
	.category__title {
		color: $primary-color;
		text-align: left;
	}

	&--extended {
		.category__title {
			text-align: center;
			color: $off-black;
			background: color("grey", "lighten-3");
			padding: rem(10px 0 15px 0);
			position: relative;
			border-top: 1px solid color("shades", "white");
			border-bottom: 1px solid color("shades", "white");

			@extend %arrow-bottom;
		}
	}

	&:not(.news-categories__columns--extended) {
		@include media-breakpoint-down(l) {
			.card {
				background: none;
				box-shadow: none;
			}

			.card-content p {
				display: none;
			}
		}
	}
}

.news-categories .row {
	padding: 0;
}

.news__item {
	.news-categories__content {
		padding: .5rem;
		hyphens: auto;

		@include media-breakpoint-up(l) {
			padding: 1.5rem;
		}

		.news__title {
			line-height: 1.15;
			font-size: rem(24px);

			@include media-breakpoint-up(l) {
				font-size: rem(24px);
			}
		}
	}
}