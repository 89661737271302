.webradio {
    @include flex(row, center, flex-start, nowrap);
    cursor: pointer;
	color: white;

    @include media-breakpoint-up(m) {
        padding-left: rem(20px);
    }

    @include media-breakpoint-up(sm) {
        flex: 1 1 0;
    }

    @include media-breakpoint-up(l) {
        padding-left: rem(70px);
    }

    &-title {
        @include flex(column, flex-start, flex-start, nowrap);
        line-height: rem(20px);
		padding-right: rem(5px);
		min-width: rem(120px);
		min-height: rem(48px);
    }

    &-title-song {
        text-transform: uppercase;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		max-width: rem(125px);

		@include media-breakpoint-up(m) {
			max-width: rem(300px);
		}
	}

	&-title-artist {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		max-width: rem(175px);

		@include media-breakpoint-up(m) {
			max-width: rem(300px);
		}	
	}

    .icon-play {
        display: inline-block;
        font-size: rem(42px);
        line-height: $navbar-height;
        height: $navbar-height;
    }

    &-cover {
        display: none;
        width: rem(35px);
        height: rem(35px);
        margin-right: rem(10px);

        > img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

.webradio--header {
    .icon-play {
        display: block;
        box-shadow: 0 0 0 0 rgba(color("shades" , "white"), 0.5);
        border-radius: 100%;
        line-height: 2.5rem;
        font-size: 2.5rem;
        height: 2.5rem;
        width: 2.5rem;
	}
	
	.icon-play--active {
		animation: pulse 3s infinite;
	}
}


