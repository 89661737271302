/*
 * Created with iconvau.lt
 */

@font-face {
	font-family: "iconvault";
	src: url("../../fonts/forecast-weather/iconvault_forecastfont.eot");
	src: url("../../fonts/forecast-weather/iconvault_forecastfont.eot?#iefix") format("embedded-opentype"),
	url("../../fonts/forecast-weather/iconvault_forecastfont.woff") format("woff"),
	url("../../fonts/forecast-weather/iconvault_forecastfont.ttf") format("truetype"),
	url("../../fonts/forecast-weather/iconvault_forecastfont.svg#iconvault") format("svg");
	font-weight: normal;
	font-style: normal;
}

.icon-weather {
	font-family: "iconvault";
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	text-decoration: inherit;
}

.icon-night:before { content: "\f100"; }
.icon-sunny:before { content: "\f101"; }
.icon-frosty:before { content: "\f102"; }
.icon-windysnow:before { content: "\f103"; }
.icon-showers:before { content: "\f104"; }
.icon-basecloud:before { content: "\f105"; }
.icon-cloud:before { content: "\f106"; }
.icon-rainy:before { content: "\f107"; }
.icon-mist:before { content: "\f108"; }
.icon-windysnowcloud:before { content: "\f109"; }
.icon-drizzle:before { content: "\f10a"; }
.icon-snowy:before { content: "\f10b"; }
.icon-sleet:before { content: "\f10c"; }
.icon-moon:before { content: "\f10d"; }
.icon-windyrain:before { content: "\f10e"; }
.icon-hail:before { content: "\f10f"; }
.icon-sunset:before { content: "\f110"; }
.icon-windyraincloud:before { content: "\f111"; }
.icon-sunrise:before { content: "\f112"; }
.icon-sun:before { content: "\f113"; }
.icon-thunder:before { content: "\f114"; }
.icon-windy:before { content: "\f115"; }