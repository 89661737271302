.theme-sidenav--toggle {
	position: fixed;
	right: 0;
	top: 50%;
	z-index: 9999;
}

.theme-sidenav {
	z-index: 99999;
	padding-top: 0;
	overflow-y: auto;

	// Reset theme styles for theme-sidenav
	[type="radio"]:checked + span:after, [type="radio"].with-gap:checked + span:after {
		background-color: color("grey", "darken-2");
	}

	[type="radio"]:checked + span:after, [type="radio"].with-gap:checked + span:after,
	[type="radio"]:checked + span:after, [type="radio"].with-gap:checked + span:before, [type="radio"].with-gap:checked + span:after,
	.select-wrapper input.select-dropdown:focus {
		border-color: color("grey", "darken-2");
	}

	.dropdown-content li > a, .dropdown-content li > span {
		color: color("grey", "darken-2");
	}

	.theme-list {
		padding: 0 1rem;

		> div {
			margin: 1rem 0;
		}
	}

	.input-field {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}

	.footer-settings,
	.header-settings {
		margin: rem(10px 0);

		span {
			width: rem(130px);
			display: inline-block;
		}
	}
}

// Font Stacks - only for themeswitcher in klickummy
// Later we use the variable $font-stack-headlines and set it in theme vars
// TODO: Remove font faces from this file

$font-stack-lato: "Lato", Helvetica, Arial, Sans-Serif; // i.e. Antenne Duesseldorf
$font-stack-roboto: "Roboto", Helvetica, Arial, Sans-Serif;
$font-stack-arial: "Arial", Sans-Serif;
$font-stack-helvetica: "Helvetica", Arial, Sans-Serif;
$font-stack-gudea: "Gudea", Arial, Sans-Serif;
$font-stack-heebo: "Heebo", Arial, Sans-Serif;
$font-stack-nunito-sans: "Nunito Sans", Arial, Sans-Serif;
$font-stack-roboto-condensed: "Roboto Condensed", Arial, Sans-Serif;
$font-stack-rubik: "Rubik", Arial, Sans-Serif;

// Headline styles
.roboto {
	h1,
	h2:not(.category__title),
	h3:not(.news__title),
	h4,
	h5,
	h6,
	.infobox__headline,
	.infobox__weather {
		font-family: $font-stack-roboto;
	}

	.news-categories,
	.news-categories__columns,
	.news__title,
	.category__title,
	.older-news h2 {
		font-family: $font-stack-roboto-condensed;
	}
}

.gudea {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	.infobox__headline,
	.infobox__weather {
		font-family: $font-stack-gudea;
	}
}

.heebo {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	.infobox__headline,
	.infobox__weather {
		font-family: $font-stack-heebo;
	}
}

.nunito-sans {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	.infobox__headline,
	.infobox__weather {
		font-family: $font-stack-nunito-sans;
	}
}

.rubik {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	.infobox__headline,
	.infobox__weather {
		font-family: $font-stack-rubik;
	}
}

// Text styles
.roboto-text {
	font-family: $font-stack-roboto;
}

.roboto-light-text {
	font-family: $font-stack-roboto;
	font-weight: 300;
}

.roboto-condensed-text {
	font-family: $font-stack-roboto-condensed;
}

.gudea-text {
	font-family: $font-stack-gudea;
}

.heebo-text {
	font-family: $font-stack-heebo;
}

.nunito-text {
	font-family: $font-stack-nunito-sans;
}

.rubik-text {
	font-family: $font-stack-rubik;
}

.lato-text {
	font-family: $font-stack-lato;
}

.arial-text {
	font-family: $font-stack-arial;
}

.helvetica-text {
	font-family: $font-stack-helvetica;
}

// Colors
.light-grey-2 {
	background-color: #ececec;

	.cover-link:not(.cover-link--invisible):hover {
		background-color: rgba(0, 0, 0, 0.05);
	}
}

.dark-news-layer {
	display: none;
}

// Dark layers
.dark-layer {
	// @include media-breakpoint-up(m) {
	// 	.current-program__info {
	// 		background-color: $transparent-layer-color;
	// 	}
	// }

	.current-program__teaser,
	.section.dark {
		background-color: $transparent-layer-color;
	}

	.section.dark {
		padding-top: 1.8rem;

		h1, h2 {
			color: color("shades", "white");
		}
	}

	/*.news-teaser__item .news-teaser__title {
		position: absolute;
		bottom: 0;
		color: color("shades", "white");
	}*/

	.carousel-slider__container {
		position: relative;

		&::after {
			content: "";
			display: block;
			background-color: $transparent-layer-color;
			height: rem(32px);
			width: 100%;
			position: absolute;
			bottom: rem(48px);

			@include media-breakpoint-up(m) {
				bottom: rem(58px)
			}
		}

		.slick-dots {
			z-index: 100;
		}
	}

	.carousel-slider .slick-dots li.slick-active button:before {
		background: $primary-color;
		border-color: $primary-color;
	}

	.dark-news-layer {
		display: block;
		position: absolute;
		bottom: 0;
		color: color("shades", "white");
		background-color: $transparent-layer-color;
		width: 100%;
		padding: 1.5rem;

		.news-teaser__title {
			color: color("shades", "white");
		}
	}

	.news-teaser__content .teaser-link,
	.dark-news-layer .teaser-link {
		background-color: $primary-color;
		color: $off-black;
		padding: rem(3px 10px);
	}

	.news-teaser__content .teaser-link {
		display: inline-block;
		margin-bottom: rem(3px);
	}

	.news-teaser__content .dark-layer-hide {
		display: none;
	}
}