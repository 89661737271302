// Custom Footer Styles
.footer__skyline {
	display: none;
	margin-bottom: rem(-40px);
	max-width: 100%;

	@include media-breakpoint-up(l) {
		max-width: 100%;
	}

	&.show-footer-image {
		display: block;
	}
}

.page-footer__content {
	@include flex(row, center, space-between, nowrap);
	padding-top: rem(10px);
	padding-bottom: rem(10px);
}

.footer-sitemap .row{
	margin-bottom: 0;
}
.footer-sitemap a {
	color: inherit;

	&.navi-link-external {
		&:after {
			content: "\F496";
			font-family: 'Material Design Icons', serif;
			margin-left: rem(10px);
		}
	}
}

.footer-sitemap ul li:first-child {
	font-weight: normal;
	margin-bottom: 1rem;
}

.footer-sitemap {
	overflow: hidden;
}