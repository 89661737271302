//Default styles

html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}

:focus {
	outline: 0;
}

body {
	background-color: $body-color;
}

.section {
	padding: 1rem 0;

	@include media-breakpoint-up(l) {
		padding: 1.8rem 0;
	}
}

.section--small-padding {
	padding: 0 0 1rem 0;

	@include media-breakpoint-up(l) {
		padding: 0 0 .7rem 0;
	}

	&:last-child {
		padding-bottom: 0;
	}
}

.section--border {
	border-top: 1px solid $border-color;
}

.z-depth-0 .card-panel,
.z-depth-0 .card {
	box-shadow: none;
}

.content-main {
	padding-top: 1rem;

	@include media-breakpoint-up(l) {
		padding-top: 1.8rem;
	}
}

// Positioning
.valign-wrapper {
	@include flex(row, center, flex-start, nowrap);

	.valign {
		display: block;
	}
}

// classic clearfix
.clearfix {
	clear: both;
}

// Z-levels
.z-depth-0 {
	box-shadow: none !important;
}

.z-depth-1 {
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.z-depth-1-half {
	box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.z-depth-2 {
	box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.z-depth-3 {
	box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.z-depth-4 {
	box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
}

.z-depth-5 {
	box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
}

.hoverable {
	transition: box-shadow .25s;
	box-shadow: 0;
}

.hoverable:hover {
	transition: box-shadow .25s;
	box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

// Dividers

.divider {
	height: 1px;
	overflow: hidden;
	background-color: color("grey", "lighten-2");
}

//  Blockquote

blockquote {
	margin: 20px 0;
	padding-left: 1.5rem;
	border-left: 5px solid $primary-color;
}

// Icon Styles

i {
	line-height: inherit;

	&.left {
		float: left;
		margin-right: 15px;
	}
	&.right {
		float: right;
		margin-left: 15px;
	}
	&.tiny {
		font-size: 1rem;
	}
	&.small {
		font-size: 2rem;
	}
	&.medium {
		font-size: 4rem;
	}
	&.large {
		font-size: 1.6rem;
	}
}

// Images
img.responsive-img,
video.responsive-video {
	max-width: 100%;
	width: 100%;
	height: auto;
}

// Pagination

.pagination {
	text-align: center;

	li {
		display: inline-block;
		text-align: center;

		a {
			padding: 0 3vw;
			font-size: 1rem;

			@include media-breakpoint-up(sm) {
				padding: rem(0 8px);
			}

			@include media-breakpoint-up(m) {
				padding: rem(0 12px);
			}
		}

		.mdi {
			font-size: 1.25rem;
		}
	}
}

// Parallax
.parallax-container {
	position: relative;
	overflow: hidden;
	height: 500px;
}

.parallax {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -1;

	img {
		display: none;
		position: absolute;
		left: 50%;
		bottom: 0;
		min-width: 100%;
		min-height: 100%;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		transform: translateX(-50%);
	}
}

// Pushpin
.pin-top, .pin-bottom {
	position: relative;
}

.pinned {
	position: fixed !important;
}

/*********************
  Transition Classes
**********************/

ul.staggered-list li {
	opacity: 0;
}

.fade-in {
	opacity: 0;
	transform-origin: 0 50%;
}

// Footer
footer.page-footer {
	margin-top: rem(20px);
	padding-top: 0;
	background-color: $footer-bg-color;

	.footer-copyright {
		overflow: hidden;
		height: 50px;
		line-height: 50px;
		color: rgba(255, 255, 255, .8);
		background-color: rgba(51, 51, 51, .08);
		@extend .light;
	}
}

// Tables
table, th, td {
	border: none;
}

table {
	width: 100%;
	display: table;

	&.bordered > thead > tr,
	&.bordered > tbody > tr {
		border-bottom: 1px solid $table-border-color;
	}

	&.striped > tbody {
		> tr:nth-child(odd) {
			background-color: $table-striped-color;
		}

		> tr > td {
			border-radius: 0px;
		}
	}

	&.highlight > tbody > tr {
		transition: background-color .25s ease;
		&:hover {
			background-color: $table-striped-color;
		}
	}

	&.centered {
		thead tr th, tbody tr td {
			text-align: center;

		}
	}

}

thead {
	border-bottom: 1px solid $table-border-color;
}

td, th {
	padding: 15px 5px;
	display: table-cell;
	text-align: left;
	vertical-align: middle;
	border-radius: 2px;
}

// Responsive Table
@include media-breakpoint-down(l) {

	table.responsive-table {
		width: 100%;
		border-collapse: collapse;
		border-spacing: 0;
		display: block;
		position: relative;

		th,
		td {
			margin: 0;
			vertical-align: top;
		}

		th {
			text-align: left;
		}
		thead {
			display: block;
			float: left;

			tr {
				display: block;
				padding: 0 10px 0 0;

				th::before {
					content: "\00a0";
				}
			}
		}
		tbody {
			display: block;
			width: auto;
			position: relative;
			overflow-x: auto;
			white-space: nowrap;

			tr {
				display: inline-block;
				vertical-align: top;
			}
		}
		th {
			display: block;
			text-align: right;
		}
		td {
			display: block;
			min-height: 1.25em;
			text-align: left;
		}
		tr {
			padding: 0 10px;
		}

		/* sort out borders */
		thead {
			border: 0;
			border-right: 1px solid $table-border-color;
		}

		&.bordered {
			th {
				border-bottom: 0;
				border-left: 0;
			}
			td {
				border-left: 0;
				border-right: 0;
				border-bottom: 0;
			}
			tr {
				border: 0;
			}
			tbody tr {
				border-right: 1px solid $table-border-color;
			}
		}

	}

}

// Collections
.collection {
	margin: $element-top-margin 0 $element-bottom-margin 0;
	border-radius: 2px;
	overflow: hidden;
	position: relative;
	border: none;

	&.older-news {
		@include createGrid(s, 6);

		.category__item {
			overflow: hidden;
			background: transparent;
			padding: rem(10px 5px);
			border-bottom: none;

			@include media-breakpoint-up(m) {
				background-color: $background-light;
			}
		}
		.category__title {
			display: block;
			margin-bottom: (16px / 2.8); //same as headlines
		}
		p {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			padding: 0;
			&.date {
				margin-top: 0;
			}
		}
	}

	.collection-item {
		background-color: $collection-bg-color;
		line-height: 1.5rem;
		padding: 10px 20px;
		margin: 0;

		// Avatar Collection
		&.avatar {
			min-height: 84px;
			padding-left: 100px; //.circle width + 2x .circle left
			position: relative;

			.circle {
				position: absolute;
				width: 70px;
				height: auto;
				overflow: hidden;
				left: 15px;
				display: inline-block;
				vertical-align: middle;
				border-radius: 0;
			}
			i.circle {
				font-size: 18px;
				line-height: 42px;
				color: #fff;
				background-color: #999;
				text-align: center;
			}
			.title {
				font-size: 16px;
			}
			p {
				margin: 0;
				overflow: hidden; //hack to show spans inside
			}
			.secondary-content {
				position: absolute;
				top: 16px;
				right: 16px;
			}
		}

		&:last-child {
			border-bottom: none;
		}

		&.active {
			background-color: $collection-active-bg-color;
			color: $collection-active-color;
			border-right: 2px solid $primary-color;

			.secondary-content {
				color: #fff;
			}
		}
	}
	a.collection-item {
		display: block;
		transition: .25s;
		color: $collection-link-color;
		&:not(.active) {
			&:hover {
				background-color: $collection-hover-bg-color;
			}
		}
	}

	&.with-header {
		.collection-header {
			background-color: $collection-bg-color;
			border-bottom: 1px solid $collection-border-color;
			padding: 10px 20px;
		}
		// .collection-item {
		// padding-left: 20px;
		// }
		.collection-item.avatar {
			padding-left: 100px;
		}
	}

}

// Made less specific to allow easier overriding
.secondary-content {
	float: right;
	color: $secondary-color;
}

.collapsible .collection {
	margin: 0;
	border: none;
}

// Badges
span.badge {
	min-width: 3rem;
	padding: 0 6px;
	text-align: center;
	font-size: 1rem;
	line-height: inherit;
	color: color('grey', 'darken-1');
	position: absolute;
	right: 15px;
	box-sizing: border-box;

	&.new {
		font-weight: 300;
		font-size: 0.8rem;
		color: #fff;
		background-color: $badge-bg-color;
		border-radius: 2px;
	}
	&.new:after {
		content: " new";
	}
}

nav ul a span.badge {
	position: static;
	margin-left: 4px;
	line-height: 0;
}

// Responsive Videos
.video-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;

	iframe, object, embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

// Progress Bar
.progress {
	position: relative;
	height: 4px;
	display: block;
	width: 100%;
	background-color: lighten($progress-bar-color, 40%);
	border-radius: 2px;
	margin: $element-top-margin 0 $element-bottom-margin 0;
	overflow: hidden;
	.determinate {
		position: absolute;
		background-color: inherit;
		top: 0;
		left: 0;
		bottom: 0;
		background-color: $progress-bar-color;
		transition: width .3s linear;
	}
	.indeterminate {
		background-color: $progress-bar-color;
		&:before {
			content: '';
			position: absolute;
			background-color: inherit;
			top: 0;
			left: 0;
			bottom: 0;
			will-change: left, right;
			// Custom bezier
			animation: indeterminate 2.1s cubic-bezier(0.650, 0.815, 0.735, 0.395) infinite;

		}
		&:after {
			content: '';
			position: absolute;
			background-color: inherit;
			top: 0;
			left: 0;
			bottom: 0;
			will-change: left, right;
			// Custom bezier
			animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.840, 0.440, 1.000) infinite;
			animation-delay: 1.15s;
		}
	}
}

@keyframes indeterminate {
	0% {
		left: -35%;
		right: 100%;
	}
	60% {
		left: 100%;
		right: -90%;
	}
	100% {
		left: 100%;
		right: -90%;
	}
}

@keyframes indeterminate-short {
	0% {
		left: -200%;
		right: 100%;
	}
	60% {
		left: 107%;
		right: -8%;
	}
	100% {
		left: 107%;
		right: -8%;
	}
}

/*******************
  Utility Classes
*******************/

.hide {
	display: none !important;
}

// Text Align
.left-align {
	text-align: left;
}

.right-align {
	text-align: right
}

.center, .center-align {
	text-align: center;
}

.left {
	float: left !important;
}

.right {
	float: right !important;
}

img.left, img.right {
	margin-bottom: 0.5em;
}

img.left {
	margin-right: 1em;
}

img.right {
	margin-left: 1em;
}

// No Text Select
.no-select {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.circle {
	border-radius: 50%;
}

.center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.truncate {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.no-padding {
	padding: 0 !important;
}

// Headline with button in one line
.headline--button {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

// Global border radius
.card-border {
	border-radius: $card-border-radius;
}

iframe {
	border-radius: $card-border-radius;
}

// Material Select
.input-field {
	li {
		span {
			color: $off-black;
		}
	}
}

.article__details ul > li {
	margin-left: 1rem;
}

.article__details ol > li {
	margin-left: 1rem;
}

.article__details ul > li {
	list-style-type: disc;
}

.autoSection > * {
	margin-bottom: 1.8rem;
	margin-top: 1.8rem;
}

.select-dropdown {
	z-index: 1000003;
}

// fix default ul style (imported materializecss) in articles / rows
main article ul, .cke_editable ul {
  &:not(.browser-default) {
    padding-left: 40px !important;
    list-style-type: initial !important;

    & > li {
      list-style-type: inherit !important;
    }
  }
}

// do not apply the previous rule to the custom dropdown components
main article ul.select-dropdown {
	list-style-type: none !important;
	padding-inline-start: 0 !important;
}

// fix for #CSC207214
.modal.open {
	z-index: 99999 !important;
}