
// Height of half map
$MAP_HEIGHT: 450px;

.map {

	&__view {
		&--half {
			height: rem($MAP_HEIGHT);	
		}
	}

	&__data {
		.collection {
			.collection-item {
				color: $off-black;

				span {
					display: inline-block;
					margin-right: rem(18px);

					&:last-child {
						margin-right: 0;
					}
				}
			}
			a {
				color: $off-black;
			}
		}

		&--half {
			overflow-y: scroll;
			max-height: rem($MAP_HEIGHT);
			height: rem($MAP_HEIGHT);
		}
	}
}